import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { INotification, notificationMessage } from "../../Device"
import { Button, Checkbox, TextField } from "@material-ui/core"
import customColors from "../../../../tailwind.customColors.json"
import { makeStyles } from "@material-ui/core/styles"
import { APIPutNotifications } from "../../../APIRequests/APIPut"
import Keycloak from "keycloak-js"
import { useSnackbar } from "notistack"

interface formErrorsType {
  [propName: string]: string[]
}

interface Props {
  keycloak: Keycloak.KeycloakInstance
  device_sn?: any
  notifications: INotification[]
  setNotifications: Dispatch<SetStateAction<INotification[]>>
  deviceMessages: notificationMessage[]
  setDeviceMessages: Dispatch<SetStateAction<notificationMessage[]>>
}

export default function Notifications({
  keycloak,
  device_sn,
  notifications,
  setNotifications,
  deviceMessages,
  setDeviceMessages,
}: Props): ReactElement {
  const [notificationsTemp, setNotificationsTemp] =
    useState<INotification[]>(notifications)
  const [pressMessageTemp, setPressMessageTemp] = useState<
    notificationMessage | undefined
  >()
  const [formErrors, setFormErrors] = useState<formErrorsType>({})

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setNotificationsTemp(notifications)

    let messageTemp: notificationMessage = { name: "buttonPressed", body: "" }
    if (deviceMessages) {
      for (let i = 0; i < deviceMessages.length; i++) {
        if (deviceMessages[i].name === "buttonPressed") {
          messageTemp = deviceMessages[i]
          break
        }
      }
    }
    setPressMessageTemp(messageTemp)
  }, [notifications, deviceMessages])

  function handleCheckBox(
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    let temp = notificationsTemp.slice()
    temp[index].email.enabled = e.target.checked
    setNotificationsTemp(temp)
  }

  function handleInput(e: React.ChangeEvent<any>, name: string, index: number) {
    const value = e.target.value
    let temp = notificationsTemp.slice()

    if (temp[index] === undefined && value !== "") {
      temp[index] = {
        email: {
          address: "",
          enabled: false,
        },
        name: "",
      }
    }

    if (name === "name") {
      if (value === "" && temp[index].email.address === "")
        temp.splice(index, 1)
      else temp[index].name = value
    } else {
      if (value === "" && temp[index].name === "") temp.splice(index, 1)
      else temp[index].email.address = value
    }

    setNotificationsTemp(temp)
  }

  function handleSave() {
    const notifications = {
      contacts: notificationsTemp,
      messages: deviceMessages.slice(),
    }

    if (pressMessageTemp !== undefined) {
      let pressMessageIndex: number | undefined = undefined
      for (let i = 0; i < notifications.messages.length; i++) {
        if (notifications.messages[i].name === "buttonPressed") {
          pressMessageIndex = i
          break
        }
      }
      if (pressMessageIndex === undefined)
        notifications.messages.push(pressMessageTemp)
      else notifications.messages[pressMessageIndex] = pressMessageTemp
    }

    let formErrorsTemp: formErrorsType = { email: [], message: [] }

    notificationsTemp.forEach((elem, index) => {
      if (elem.email.address === "")
        formErrorsTemp.email[index] = "E-mail field is required"
      else if (elem.email.address.search("@") === -1)
        formErrorsTemp.email[index] = "The e-mail is invalid"
    })

    if (pressMessageTemp && pressMessageTemp.body.length > 256)
      formErrorsTemp.message = ["Message can't be longer than 256 characters"]

    setFormErrors(formErrorsTemp)

    if (
      formErrorsTemp.email.length === 0 &&
      formErrorsTemp.message.length === 0
    ) {
      APIPutNotifications({ keycloak, device_sn, notifications }).then(
        (response) => {
          if (response.status === 200) {
            setNotifications(notificationsTemp)
            setDeviceMessages(notifications.messages)
            enqueueSnackbar("Receivers successfully updated", {
              variant: "default",
            })
          } else {
            enqueueSnackbar(response.data.message, { variant: "error" })
          }
        }
      )
    }
  }

  const useStylesCh = makeStyles({
    root: {
      "&:hover": {
        backgroundColor: "#eee",
      },
      "&$checked": {
        "&:hover": {
          backgroundColor: "#eee",
        },
      },
    },
    checked: {},
  })
  const classesCh = useStylesCh()

  const useButtonStyles = makeStyles({
    root: {
      "&:focus": {
        outline: "none",
      },
    },
  })
  const classesButton = useButtonStyles()

  const useStylesInput = makeStyles({
    root: {
      backgroundColor: "white",
      "&:focus": { outline: "1px solid #aaa" },
      "& fieldset": { border: "none" },
      "&$error": {
        // backgroundColor: customColors.danger,
        // color: 'white',
      },
    },
    error: {},
  })
  const classesInput = useStylesInput()

  return (
    <div>
      <Button
        style={{
          backgroundColor: customColors.orange,
          color: "white",
          borderRadius: "0",
          marginBottom: "1rem",
          textTransform: "unset",
        }}
        classes={{ root: classesButton.root }}
        onClick={handleSave}
      >
        Save settings
      </Button>
      <div className="px-20 pb-8">
        <h1>Message to be sent after Press push button has been pressed.</h1>
        <TextField
          multiline={true}
          rows={3}
          rowsMax={10}
          value={pressMessageTemp?.body || ""}
          placeholder="Type your message"
          variant="outlined"
          style={{ width: "100%" }}
          InputProps={{
            style: {
              borderRadius: 0,
              border: `1px solid ${customColors.sidebar}`,
            },
            classes: { root: classesInput.root, error: classesInput.error },
          }}
          InputLabelProps={{ shrink: true }}
          onChange={(e) =>
            setPressMessageTemp({ name: "buttonPressed", body: e.target.value })
          }
          error={formErrors.message && formErrors.message.length > 0}
          helperText={(formErrors.message && formErrors.message[0]) || ""}
        />
      </div>
      <div>Message distribution list.</div>
      <table className="border border-black w-full border-collapse">
        <thead>
          <tr className="bg-sidebar text-white">
            <th className="border border-black p-2 w-12">Nr</th>
            <th className="border border-black p-2 w-60">Name</th>
            <th className="border border-black p-2">E-mail</th>
            <th className="border border-black p-2 w-28">Active</th>
          </tr>
        </thead>
        <tbody>
          {Array(10)
            .fill("")
            .map((val, index) => (
              <tr key={index}>
                <td className="border border-black p-2.5 text-center">
                  {index + 1}
                </td>
                <td className="border border-black p-2.5">
                  <TextField
                    type="text"
                    variant="outlined"
                    value={notificationsTemp[index]?.name || ""}
                    style={{ width: "100%" }}
                    InputProps={{
                      style: { borderRadius: 0, border: "none" },
                      classes: {
                        root: classesInput.root,
                        error: classesInput.error,
                      },
                    }}
                    inputProps={{ style: { padding: "0.2rem 0.5rem" } }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleInput(e, "name", index)}
                  />
                </td>
                <td className="border border-black p-2.5">
                  <TextField
                    type="text"
                    variant="outlined"
                    value={notificationsTemp[index]?.email.address || ""}
                    error={formErrors.email && index in formErrors.email}
                    helperText={
                      (formErrors.email && formErrors.email[index]) || ""
                    }
                    style={{ width: "100%" }}
                    InputProps={{
                      style: { borderRadius: 0, border: "none" },
                      classes: {
                        root: classesInput.root,
                        error: classesInput.error,
                      },
                    }}
                    inputProps={{ style: { padding: "0.2rem 0.5rem" } }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleInput(e, "email", index)}
                  />
                </td>
                <td className="border border-black text-center">
                  {notificationsTemp[index] ? (
                    <Checkbox
                      style={{ fontSize: 28, color: customColors.sidebar }}
                      checked={notificationsTemp[index].email.enabled}
                      onChange={(e) => handleCheckBox(e, index)}
                      classes={{
                        root: classesCh.root,
                        checked: classesCh.checked,
                      }}
                    />
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}
