import axios, { AxiosResponse } from "axios"
import Keycloak from "keycloak-js"
import React from "react"

export function updateKeycloakToken(keycloak: Keycloak.KeycloakInstance) {
  let minValidity = 60
  const exp = keycloak.idTokenParsed?.exp
  const iat = keycloak.idTokenParsed?.iat
  if (exp && iat) minValidity = Math.floor((exp - iat) / 2)

  keycloak.updateToken(minValidity).catch(function () {
    console.log("Can't update keycloak token")
  })
}

export interface IAPIGETProps {
  keycloak: Keycloak.KeycloakInstance
  device_sn?: string | null
  product?: string
  hash?: string
  setImage?: React.Dispatch<any>
  group?: string | null
  nextPageToken?: string | null
}

export async function APIGetDevice({ keycloak, device_sn }: IAPIGETProps) {
  updateKeycloakToken(keycloak)

  //FOR SOME Reason JS adds quotes to the device_sn variable so we have to remove them before using them in
  let string = JSON.stringify(device_sn)
  let no_quotes = string.replace(/['"\\]+/g, "")

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.get(`/devices/${no_quotes}`)

  return response
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIGetProduct({ keycloak, product }: IAPIGETProps) {
  updateKeycloakToken(keycloak)

  //FOR SOME Reason JS adds quotes to the device_sn variable so we have to remove them before using them in
  //console.log(product)
  let string = JSON.stringify(product)
  let no_quotes = string.replace(/['"\\]+/g, "")

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL + `products/`,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.get(`/${no_quotes}`)

  return response
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIGetDeviceType({ keycloak, device_sn }: IAPIGETProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  //FOR SOME Reason JS adds quotes to the device_sn variable so we have to remove them before using them in
  let string = JSON.stringify(device_sn)
  let no_quotes = string.replace(/['"\\]+/g, "")
  const response = authAxios.get(`/devices/${no_quotes}`)
  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

interface APISearchDeviceProps {
  keycloak: Keycloak.KeycloakInstance
  search?: string
  product?: string
  imsi?: string
  group?: string
  nextPageToken?: string
  pageSize?: number | string
}

export async function APISearchDevice({
  keycloak,
  search = "%25",
  product = "",
  imsi = "",
  group = "",
  pageSize = "",
  nextPageToken = "",
}: APISearchDeviceProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  if (search === "") {
    search = "%25"
  }
  const response = authAxios.get(
    `devices?term=${search}&product=${product}&imsi=${imsi}&group=${group}&pageToken=${nextPageToken}&pageSize=${pageSize}`
  )

  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIGetAllGroups({ keycloak }: IAPIGETProps) {
  updateKeycloakToken(keycloak)
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.get(`/groups`)
  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIGetDeviceConfig({
  keycloak,
  device_sn,
}: IAPIGETProps) {
  updateKeycloakToken(keycloak)

  //FOR SOME Reason JS adds quotes to the device_sn variable so we have to remove them before using them in
  let string = JSON.stringify(device_sn)
  let no_quotes = string.replace(/['"\\]+/g, "")

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.get(`/devices/${no_quotes}/config`)
  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIGetAPItokens({ keycloak }: IAPIGETProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.get(`/api-keys`)

  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export interface apiFeature {
  id: string
  name: string
  type: string
  enabled?: boolean
}

export interface AxiosResponseExtended extends AxiosResponse {
  forwardedImageData?: apiFeature
}

export interface IAPIGETImagesProps {
  keycloak: Keycloak.KeycloakInstance
  id: string
  imageFeature?: apiFeature
}

export async function APIGetAPIFeatures({
  keycloak,
  id,
  imageFeature,
}: IAPIGETImagesProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
    responseType: "blob",
  })

  const response = authAxios.get(`/features/${id}`)
  return response
    .then((res) => {
      const responseExtended: AxiosResponseExtended = {
        ...res,
        forwardedImageData: imageFeature,
      }
      return responseExtended
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIGetAPIGroups({ keycloak }: IAPIGETProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.get(`/groups`)

  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIGetGroupDevices({ keycloak, group }: IAPIGETProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.get(`/groups/${group}/devices`)

  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIGetNotifications({
  keycloak,
  device_sn,
}: IAPIGETProps) {
  //FOR SOME Reason JS adds quotes to the device_sn variable so we have to remove them before using them in
  let string = JSON.stringify(device_sn)
  let no_quotes = string.replace(/['"\\]+/g, "")

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.get(`/devices/${no_quotes}/notification-settings`)

  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIGetDeviceFeatures({
  keycloak,
  device_sn,
}: IAPIGETProps) {
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.get(`/devices/${device_sn}/features`)
  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIGetProductFeatures({
  keycloak,
  product,
}: IAPIGETProps) {
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.get(`/products/${product}/features`)
  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

interface getSchemaProps {
  keycloak: Keycloak.KeycloakInstance
  productType: string
}

export async function APIGetConfigSchema({
  keycloak,
  productType,
}: getSchemaProps) {
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.get(`/products/${productType}/config-schema`)
  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}
