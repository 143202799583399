import bgImage from "../../src/img/help_bg.jpg"
import { Tooltip } from "@material-ui/core"

export default function HelpPage() {
  return (
    <div
      className="h-full w-full bg-cover"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div
        className="p-32 text-2xl text-center"
        style={{ width: "60rem", fontFamily: "RobotoCondensed" }}
      >
        <div>S-2CONNECT Configurator Online user manual</div>
        <div>
          <Tooltip title="https://s-2connect.com/configurator/manual" arrow>
            <a
              href="https://s-2connect.com/configurator/manual"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#3db7e4" }}
            >
              s-2connect.com/configurator/manual
            </a>
          </Tooltip>
        </div>
        <div className="mt-4">User manuals for the products</div>
        <div>
          <Tooltip title="https://s-2connect.com" arrow>
            <a
              href="https://s-2connect.com"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#3db7e4" }}
            >
              s-2connect.com
            </a>
          </Tooltip>
        </div>
        <div className="mt-4">Contact us</div>
        <div>
          <Tooltip title="mailto:support.connectivity@ttelectronics.com" arrow>
            <a
              href="mailto:support.connectivity@ttelectronics.com"
              style={{ color: "#3db7e4" }}
            >
              support.connectivity@ttelectronics.com
            </a>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
