import { BrowserRouter, Route, Switch } from "react-router-dom"
import HomePage from "../HomePage"
import GroupMgmPage from "../GroupMgmPage"
import CopyPage from "../CopyPage"
import Header from "../DeviceHeader/Header"
import SideBarSections from "../SideBar/SideBarSections"
import Logo from "../../img/TTLogo.png"
import ProfilePage from "../ProfilePage/ProfilePage"
import Keycloak from "keycloak-js"
import SelectDevice from "../DeviceLayoutContent/SelectDevice"
import Device, { IDeviceInfo } from "../DeviceLayoutContent/Device"
import { Dispatch, SetStateAction } from "react"
import HelpPage from "../HelpPage"

export interface IDeviceLayoutsProps {
  keycloak: Keycloak.KeycloakInstance
  selectedDeviceInfo?: IDeviceInfo
  setSelectedDeviceInfo: Dispatch<SetStateAction<IDeviceInfo | undefined>>
  selectedMenu: string
  setSelectedMenu: Dispatch<SetStateAction<string>>
}

export default function DeviceLayouts({
  keycloak,
  selectedDeviceInfo,
  setSelectedDeviceInfo,
  selectedMenu,
  setSelectedMenu,
}: IDeviceLayoutsProps) {
  return (
    <BrowserRouter>
      <div className="flex flex-row min-h-screen">
        <div
          className="flex-none flex flex-col bg-sidebar border-r-2 border-black"
          style={{ width: "16.2rem" }}
        >
          <div className="bg-white h-20 flex justify-center items-center p-4">
            <a
              href="https://www.ttelectronics.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Logo} alt="logo" />
            </a>
          </div>
          <SideBarSections
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            keycloak={keycloak}
          />
        </div>
        <div className="flex-grow flex flex-col">
          <div className="bg-white border-b-2 border-black h-20 py-10 pl-10">
            <Header
              Title="S-2CONNECT Configurator"
              keycloak={keycloak}
              selectedDeviceInfo={selectedDeviceInfo}
              setSelectedDeviceInfo={setSelectedDeviceInfo}
              setSelectedMenu={setSelectedMenu}
            />
          </div>
          <div className="h-full">
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route path="/groupMgm">
                <GroupMgmPage keycloak={keycloak} />
              </Route>
              <Route path="/copy">
                <CopyPage keycloak={keycloak} />
              </Route>
              <Route exact path="/config">
                <SelectDevice
                  keycloak={keycloak}
                  setSelectedDeviceInfo={setSelectedDeviceInfo}
                />
              </Route>
              <Route exact path={`/config/:selectedDeviceId`}>
                <Device
                  keycloak={keycloak}
                  selectedDeviceInfo={selectedDeviceInfo}
                  setSelectedDeviceInfo={setSelectedDeviceInfo}
                />
              </Route>
              <Route path="/help">
                <HelpPage />
              </Route>
              <Route path="/profile">
                <ProfilePage keycloak={keycloak} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </BrowserRouter>
  )
}
