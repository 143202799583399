import { KeycloakInstance } from "keycloak-js"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Close } from "@material-ui/icons"
import { IDeviceInfo } from "../Device"

interface Props {
  keycloak: KeycloakInstance
  deviceInfo: IDeviceInfo
  setMasterDeviceOpened: Dispatch<SetStateAction<boolean>>
}

export default function MasterDeviceSubMenu({
  keycloak,
  deviceInfo,
  setMasterDeviceOpened,
}: Props) {
  const [masterDeviceList, setMasterDeviceList] = useState<string[]>([])

  useEffect(() => {
    // make an API call here for get master devices and set it by `setMasterDeviceList()`
    setMasterDeviceList([])
  }, [keycloak, deviceInfo])

  const aliasAndSn =
    deviceInfo.alias !== deviceInfo.sn
      ? `${deviceInfo.alias} (${deviceInfo.sn})`
      : deviceInfo.sn

  return (
    <div
      className="border-8 border-sidebar flex flex-col"
      style={{ minWidth: "400px" }}
    >
      <div className="flex justify-between bg-popper">
        <div
          className="px-2 border border-sidebar bg-customGrey text-white text-center"
          style={{
            height: "fit-content",
            margin: "0.5rem 20px",
            minWidth: "9rem",
          }}
        >
          Master device for {aliasAndSn}
        </div>
        <Close
          style={{ fontSize: "30px", cursor: "pointer" }}
          onClick={() => setMasterDeviceOpened(false)}
        />
      </div>
      <div
        className="flex flex-col gap-4 bg-popper"
        style={{ padding: "10px 40px 20px 20px" }}
      >
        <table className="border border-black w-full border-collapse">
          <thead>
            <tr className="bg-sidebar text-white">
              <td className="w-72 border border-black p-2 text-center">
                Serial number
              </td>
            </tr>
          </thead>
          <tbody>
            {masterDeviceList.length === 0 ? (
              <tr className="bg-white">
                <td className="border border-black p-2">&nbsp;</td>
              </tr>
            ) : (
              masterDeviceList.map((elem, index) => (
                <tr className="bg-white" key={index}>
                  <td className="border border-black px-2">{elem}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
