import { IDeviceInfo, IGroup } from "../Device"
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  ChangeEvent,
} from "react"
import { APIGetAllGroups } from "../../APIRequests/APIGet"
import Keycloak from "keycloak-js"
import { Close } from "@material-ui/icons"
import { Checkbox, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import customColors from "../../../tailwind.customColors.json"
import { APIPutDeviceGroup } from "../../APIRequests/APIPut"
import { useSnackbar } from "notistack"

interface IAddToGroupProps {
  keycloak: Keycloak.KeycloakInstance
  setDeviceInfo: Dispatch<SetStateAction<IDeviceInfo | undefined>>
  deviceInfo: IDeviceInfo
  setGroupOpened: Dispatch<boolean>
}

export default function AddToGroupSubMenu({
  keycloak,
  setDeviceInfo,
  deviceInfo,
  setGroupOpened,
}: IAddToGroupProps) {
  const [groupList, setGroupList] = useState<IGroup[]>([])
  const [deviceGroupsTemp, setDeviceGroupsTemp] = useState<IGroup[]>(
    deviceInfo.groups || []
  )

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    APIGetAllGroups({ keycloak: keycloak }).then((response) => {
      if (response.status === 200) {
        setGroupList(response.data)
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" })
      }
    })
  }, [keycloak, enqueueSnackbar])

  const useStyles = makeStyles({
    rootCh: {
      "&:hover": {
        backgroundColor: "#eee",
      },
      "&$checked": {
        "&:hover": {
          backgroundColor: "#eee",
        },
      },
    },
    checked: {},

    rootBtn: {
      "&:focus": { outline: "none" },
    },
  })
  const classes = useStyles()

  function deviceInGroup(group: IGroup) {
    const groupId = group.id
    let inGroup = false

    for (let i = 0; i <= deviceGroupsTemp.length; i++) {
      if (deviceGroupsTemp[i]?.id === groupId) {
        inGroup = true
        break
      }
    }

    return inGroup
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>, groupObj: IGroup) {
    let tempArr = deviceGroupsTemp.slice()
    if (e.target.checked) {
      tempArr.push(groupObj)
    } else {
      let arrIndex = 0

      for (let i = 0; i <= tempArr.length; i++) {
        if (tempArr[i].id === groupObj.id) {
          arrIndex = i
          break
        }
      }

      tempArr.splice(arrIndex, 1)
    }

    setDeviceGroupsTemp(tempArr)
  }

  const handleSave = () => {
    let groupsTemp: { id: string }[] = []
    deviceGroupsTemp.forEach((elem) => groupsTemp.push({ id: elem.id }))

    APIPutDeviceGroup({
      keycloak: keycloak,
      device_sn: deviceInfo.sn,
      groups: groupsTemp,
    }).then((response) => {
      if (response.status === 200) {
        deviceInfo.groups = deviceGroupsTemp
        setDeviceInfo(deviceInfo)
        setGroupOpened(false)
        enqueueSnackbar("Device groups successfully updated", {
          variant: "default",
        })
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" })
      }
    })
  }

  return (
    <div
      className="border-8 border-sidebar flex flex-col"
      style={{ minWidth: "400px" }}
    >
      <div className="flex-none text-right bg-popper">
        <Close
          style={{ fontSize: "30px", cursor: "pointer" }}
          onClick={() => setGroupOpened(false)}
        />
      </div>
      <div
        className="flex-grow bg-popper"
        style={{ padding: "10px 40px 20px 20px" }}
      >
        {/* table */}
        <div className="flex flex-col border-l border-t border-r border-black">
          {/* head row */}
          <div className="flex w-full bg-sidebar text-white font-bold">
            <div
              className="w-full border-b border-r border-black text-center"
              style={{ padding: "8px 0" }}
            >
              Groups
            </div>
            <div
              className="border-b border-black text-center"
              style={{ padding: "8px 0", width: "6.9rem" }}
            >
              Add
            </div>
          </div>
          {groupList.map((groupObj, index) => {
            return (
              <div className="flex w-full bg-white" key={index}>
                <div className="w-full border-b border-r border-black p-2">
                  {groupObj.name}
                </div>
                <div
                  className="border-b border-black text-center"
                  style={{ width: "7rem" }}
                >
                  <Checkbox
                    style={{ fontSize: 28, color: customColors.sidebar }}
                    checked={deviceInGroup(groupObj)}
                    onChange={(e) => handleChange(e, groupObj)}
                    classes={{ root: classes.rootCh, checked: classes.checked }}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <Button
          variant="contained"
          disableElevation
          style={{
            backgroundColor: customColors.orange,
            color: "white",
            borderRadius: 0,
            marginTop: "20px",
          }}
          classes={{ root: classes.rootBtn }}
          size="large"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
