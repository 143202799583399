import React from "react"
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import customColors from "../../../../tailwind.customColors.json"

interface componentProps {
  imageName: string
  selected: string
  customDisabled: boolean
  handleActiveImage: (imageName: string, value: boolean) => void
}

export default function RadioButtonsGroup({
  imageName,
  selected,
  customDisabled,
  handleActiveImage,
}: componentProps) {
  const useStyles = makeStyles({
    root: {
      color: "black",
      "&$checked": {
        color: customColors.orange,
        "&:hover": {
          backgroundColor: `${customColors.popper}33`,
        },
      },
      "&:hover": {
        backgroundColor: `${customColors.popper}33`,
      },
    },
    checked: {},
  })
  const classes = useStyles()

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={selected}
        onChange={(e) =>
          handleActiveImage(imageName, e.target.value === "custom")
        }
      >
        <FormControlLabel
          value="default"
          control={
            <Radio classes={{ root: classes.root, checked: classes.checked }} />
          }
          label="Default"
        />
        <FormControlLabel
          value="custom"
          control={
            <Radio classes={{ root: classes.root, checked: classes.checked }} />
          }
          label="User defined"
          disabled={customDisabled}
        />
      </RadioGroup>
    </FormControl>
  )
}
