import axios from "axios"
import Keycloak from "keycloak-js"
import { updateKeycloakToken } from "./APIGet"

export interface IAPIPostProps {
  TokenName: string
  keycloak: Keycloak.KeycloakInstance
}

export async function APICreateToken({ keycloak, TokenName }: IAPIPostProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const Token = authAxios.post("/api-keys", { name: TokenName })
  return Token.then((Token) => {
    return Token
  }).catch((err) => {
    return err.response
  })
}

interface CreateGroupProps {
  keycloak: Keycloak.KeycloakInstance
  groupName: string
}

export async function APICreateGroup({
  keycloak,
  groupName,
}: CreateGroupProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.post("/groups", { name: groupName })

  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}
