import S2HubImage from "../../img/devices/S-2Hub_c.jpg"
import S2SensorImage from "../../img/devices/S-2Sensor_c.png"
import SPressImage from "../../img/devices/Press_c.png"
import s18m02image from "../../img/devices/s18m02_c.png"
import s22e01image from "../../img/devices/s22e01_c.png"
import s22zXYimage from "../../img/devices/s22zXX.png"

export interface IDeviceImageProps {
  deviceType?: string
}

export default function DeviceImage({ deviceType }: IDeviceImageProps) {
  let deviceSrc = ""
  let sizeProp: { [propName: string]: string } = { width: "100%" }
  if (deviceType === "s18f01") {
    deviceSrc = SPressImage
  } else if (deviceType === "s19p01") {
    deviceSrc = S2SensorImage
  } else if (deviceType === "s19a01_107") {
    deviceSrc = S2HubImage
  } else if (deviceType === "s18m02") {
    deviceSrc = s18m02image
    sizeProp = { height: "100%" }
  } else if (deviceType === "s21c01") {
    deviceSrc = s18m02image
    sizeProp = { height: "100%" }
  } else if (deviceType === "s22e01") {
    deviceSrc = s22e01image
    sizeProp = { height: "100%" }
  } else if (deviceType === "s22z11") {
    deviceSrc = s22zXYimage
    sizeProp = { height: "100%" }
  } else if (deviceType === "s22e02") {
    deviceSrc = s22e01image
    sizeProp = { height: "100%" }
  } else if (deviceType === "s22e11") {
    deviceSrc = s22e01image
    sizeProp = { height: "100%" }
  } else if (deviceType === "s22e12") {
    deviceSrc = s22e01image
    sizeProp = { height: "100%" }
  }

  if (deviceSrc !== "")
    return (
      <img
        src={deviceSrc}
        alt="device"
        style={{ margin: "0 auto" }}
        {...sizeProp}
      />
    )
  else return <div style={{ height: 100 }} />
}
