import { ReactElement } from "react"
import DeviceImage from "./DeviceImage"
import { IDeviceInfo } from "./Device"
import { IDeviceConfig } from "../APIRequests/APIPut"
import { productName } from "./SelectDevice"

interface Props {
  device?: IDeviceConfig
  deviceInfo: IDeviceInfo
}

export default function DeviceInfo({
  device,
  deviceInfo,
}: Props): ReactElement {
  return (
    <>
      {device ? (
        <>
          <div style={{ height: "auto" }}>
            <DeviceImage deviceType={deviceInfo.product} />
          </div>
          <div className="text-center">
            <div>Device Type:</div>
            <div className="text-lg">{productName[deviceInfo.product]}</div>
          </div>
          <div className="text-center">
            <div>Serial number:</div>
            <div className="text-lg">{deviceInfo.sn}</div>
          </div>
          <div className="text-center">
            <div>Alias:</div>
            <div className="text-lg">{deviceInfo.alias}</div>
          </div>
          <div className="text-center">
            <div>FW version:</div>
            <div className="text-lg">{deviceInfo.fwVer}</div>
          </div>
        </>
      ) : (
        <div />
      )}
    </>
  )
}
