import { KeycloakInstance } from "keycloak-js"
import React, { useEffect, useState } from "react"
import {
  APIPutCopyFeatures,
  APIPutDeviceConfig,
  APIPutNotifications,
  IDeviceConfig,
} from "./APIRequests/APIPut"
import { APIGetDeviceConfig, APIGetNotifications } from "./APIRequests/APIGet"
import { useSnackbar } from "notistack"
import SearchBar from "./DeviceHeader/SearchBar"
import {
  IDeviceInfo,
  INotification,
  notificationMessage,
} from "./DeviceLayoutContent/Device"
import customColors from "../tailwind.customColors.json"
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

export interface CopyPageProps {
  keycloak: KeycloakInstance
}

export default function CopyPage({ keycloak }: CopyPageProps) {
  const [deviceFrom, setDeviceFrom] = useState<IDeviceInfo | undefined>()
  const [deviceTo, setDeviceTo] = useState<IDeviceInfo | undefined>()
  const [configFrom, setConfigFrom] = useState<IDeviceConfig | undefined>()
  const [notificationFrom, setNotificationFrom] = useState<
    { contacts: INotification[]; messages: notificationMessage[] } | undefined
  >()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (deviceFrom) {
      APIGetDeviceConfig({ keycloak, device_sn: deviceFrom.sn }).then(
        (response) => {
          if (response.status === 200) {
            setConfigFrom(response.data.data)
          } else {
            enqueueSnackbar(response.data.message, { variant: "error" })
          }
        }
      )

      APIGetNotifications({ keycloak, device_sn: deviceFrom.sn }).then(
        (response) => {
          if (response.status === 200) {
            setNotificationFrom(response.data)
          } else {
            enqueueSnackbar(response.data.message, { variant: "error" })
          }
        }
      )
    }
  }, [deviceFrom, keycloak, enqueueSnackbar])

  function saveHandler() {
    if (deviceTo?.sn && deviceFrom?.sn && configFrom && notificationFrom) {
      // copy config

      APIPutDeviceConfig({
        keycloak,
        device_sn: deviceTo.sn,
        config: configFrom,
      }).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Configuration successfully copied")
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" })
        }
      })

      // copy notification

      APIPutNotifications({
        keycloak,
        device_sn: deviceTo.sn,
        notifications: notificationFrom,
      }).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Notifications successfully copied", {
            variant: "default",
          })
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" })
        }
      })

      // copy features

      APIPutCopyFeatures({
        keycloak,
        device_sn_from: deviceFrom.sn,
        device_sn_to: deviceTo.sn,
      }).then((response) => {
        if (response.status < 300) {
          enqueueSnackbar("Features successfully copied", {
            variant: "default",
          })
        } else if (response.data) {
          enqueueSnackbar(response.data.message, { variant: "error" })
        } else {
          enqueueSnackbar(
            `Features: ${response.status} ${response.statusText}`,
            {
              variant: "error",
            }
          )
        }
      })
    }
  }

  const useButtonStyles = makeStyles({
    root: {
      backgroundColor: customColors.orange,
      color: "white",
      "&$disabled": {
        backgroundColor: customColors.grey,
        color: customColors.popper,
      },
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        backgroundColor: customColors.orange,
        color: "white",
      },
    },
    disabled: {},
  })
  const classesButton = useButtonStyles()

  return (
    <div className="flex flex-col items-center w-full p-10">
      <div className="flex gap-14">
        <div>
          <div>Select device to copy configuration from:</div>
          <SearchBar keycloak={keycloak} onSelect={setDeviceFrom} />
        </div>
        <div>
          <div>Select device to copy configuration to:</div>
          <SearchBar
            keycloak={keycloak}
            onSelect={setDeviceTo}
            productFilter={deviceFrom?.product}
            excludeDevice={deviceFrom?.sn}
          />
        </div>
        <div className="pt-8">
          <Button
            style={{
              borderRadius: "0",
              marginBottom: "1rem",
              textTransform: "unset",
            }}
            classes={{
              root: classesButton.root,
              disabled: classesButton.disabled,
            }}
            disabled={!configFrom || !deviceTo}
            onClick={saveHandler}
          >
            Copy
          </Button>
        </div>
      </div>
      <div className="pt-10">
        Please note that configuration can only be copied between devices with
        the same type.
      </div>
      <div>
        Settings from the sub menu such as Alias and group belongings are not
        copied.
      </div>
    </div>
  )
}
