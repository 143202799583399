import { ReactElement, useEffect, useState } from "react"
import { KeycloakInstance, KeycloakProfile } from "keycloak-js"

interface ProfilePageProps {
  keycloak: KeycloakInstance
}

export default function ProfilePage({
  keycloak,
}: ProfilePageProps): ReactElement {
  const [userProfile, setUserProfile] = useState<KeycloakProfile>()

  useEffect(() => {
    keycloak
      .loadUserProfile()
      .then((response) => {
        setUserProfile(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [keycloak])

  const tableData = [
    {
      name: "Username",
      value: userProfile?.username || "",
    },
    {
      name: "Email",
      value: userProfile?.email || "",
    },
    {
      name: "Email verified",
      value: userProfile
        ? userProfile.emailVerified
          ? "Verified"
          : "Unverified"
        : "",
    },
    {
      name: "First name",
      value: userProfile?.firstName || "",
    },
    {
      name: "Last name",
      value: userProfile?.lastName || "",
    },
    {
      name: "Roles",
      value: keycloak?.tokenParsed?.resource_access?.account.roles.map(
        (elem, index) => <div key={index}>{elem}</div>
      ),
    },
  ]

  return (
    <>
      {!userProfile ? (
        <div className="p-10">Loading...</div>
      ) : (
        <div className="flex p-10 justify-center">
          <table className="border-collapse">
            <tbody>
              {tableData.map((elem, index) => (
                <tr key={index}>
                  <td className="bg-sidebar text-white px-6 py-4 border border-black w-40">
                    {elem.name}
                  </td>
                  <td className="px-6 py-4 bg-popper border border-black w-60">
                    {elem.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}
