import { IDeviceConfig } from "../../../APIRequests/APIPut"
import { DeviceFormConfig } from "../../DevicePage"
import DevicePage from "../../DevicePage"

interface DeviceTabComponentProps {
  deviceConfig: IDeviceConfig
  saveConfig: (config: IDeviceConfig) => void
  configSchema: { [p: string]: any } | undefined
}

export default function Timers({
  deviceConfig,
  saveConfig,
  configSchema,
}: DeviceTabComponentProps) {
  const formConfig: DeviceFormConfig = {
    Ping: ["schedule.station#press", "schedule.station#pressDisabled"],
    "Return delay": ["btn.enable", "btn.holdoff_time"],
    Battery: ["bat.enable", "bat.lowlim", "bat.highlim", "bat.prio"],
  }

  return (
    <DevicePage
      deviceConfig={deviceConfig}
      saveConfig={saveConfig}
      configSchema={configSchema}
      formConfig={formConfig}
    />
  )
}
