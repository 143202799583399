import SearchBar from "./SearchBar"
import Keycloak from "keycloak-js"
import { useHistory } from "react-router-dom"
import { IDeviceInfo } from "../DeviceLayoutContent/Device"
import { Dispatch, SetStateAction } from "react"

export interface IHeaderProps {
  Title?: string
  keycloak: Keycloak.KeycloakInstance
  selectedDeviceInfo?: IDeviceInfo
  setSelectedDeviceInfo: Dispatch<SetStateAction<IDeviceInfo | undefined>>
  setSelectedMenu: Dispatch<SetStateAction<string>>
}

export default function Header({
  Title,
  keycloak,
  selectedDeviceInfo,
  setSelectedDeviceInfo,
  setSelectedMenu,
}: IHeaderProps) {
  let history = useHistory()

  function onDeviceSelect(deviceInfo: IDeviceInfo) {
    if (deviceInfo?.sn) {
      setSelectedDeviceInfo(deviceInfo)
      history.push(`/config/${deviceInfo.sn}`)
      setSelectedMenu("config")
    }
  }

  return (
    <div className="flex justify-between items-center h-full">
      <h1 className="font-sans text-xl font-bold">{Title}</h1>
      <div className="w-80">
        <SearchBar
          keycloak={keycloak}
          onSelect={onDeviceSelect}
          selectedDeviceInfo={selectedDeviceInfo}
        />
      </div>
    </div>
  )
}
