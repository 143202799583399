import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import UploadButton from "./UploadButton"
import RadioButtonsGroup from "./RadioButtonsGroup"
import Keycloak from "keycloak-js"
import { Button } from "@material-ui/core"
import customColors from "../../../../tailwind.customColors.json"
import { makeStyles } from "@material-ui/core/styles"
import { AxiosResponse } from "axios"
import {
  APIPutFeatures,
  APIPutFeaturesStatus,
} from "../../../APIRequests/APIPut"
import DeleteButton from "./DeleteButton"
import { APIDelFeatureFromDevice } from "../../../APIRequests/APIDel"
import { useSnackbar } from "notistack"

export interface Props {
  device_sn?: string
  deviceImages: ImageFeature[]
  setDeviceImages: Dispatch<SetStateAction<ImageFeature[]>>
  keycloak: Keycloak.KeycloakInstance
  defaultImages: ImageFeature[]
}

export interface ImageFeature {
  id: string
  name: string
  image: any
  enabled?: boolean
  file?: File
}

interface DeviceImagesStructured {
  [propName: string]: ImageFeature
}

export default function ImageUpload({
  deviceImages,
  setDeviceImages,
  keycloak,
  device_sn,
  defaultImages,
}: Props): ReactElement {
  const imageByName: DeviceImagesStructured = {}
  deviceImages.forEach((elem) => {
    imageByName[elem.name] = elem
  })
  const [deviceImagesForm, setDeviceImagesForm] =
    useState<DeviceImagesStructured>(imageByName)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const imageByNameTemp: DeviceImagesStructured = {}
    deviceImages.forEach((elem) => {
      imageByNameTemp[elem.name] = elem
    })

    setDeviceImagesForm(imageByNameTemp)
  }, [deviceImages])

  function handleActiveImage(imageName: string, value: boolean) {
    setDeviceImagesForm((prevState) => {
      let temp = { ...prevState }
      temp[imageName].enabled = value
      return temp
    })
  }

  const imageNames = ["press", "wait", "msg_sent"]
  let namesToKeys: { [propName: string]: number } = {}
  defaultImages.forEach((defaultValue, defaultKey) => {
    for (let i = 0; i < imageNames.length; i++) {
      if (imageNames[i] === defaultValue.name) {
        namesToKeys[defaultValue.name] = defaultKey
        break
      }
    }
  })

  function handleImagesSave() {
    let promises: Promise<void | AxiosResponse>[] = []
    let hasError = false
    for (const [, value] of Object.entries(deviceImagesForm)) {
      if (value.image === "") {
        // delete image

        if (value.id !== "") {
          promises.push(
            APIDelFeatureFromDevice({
              keycloak,
              device_sn: device_sn || "",
              featureId: value.id,
            })
          )
        }
      } else if (value.file !== undefined) {
        // new image

        const formData = new FormData()
        formData.append("name", value.name)
        formData.append("file", value.file)
        formData.append("type", "image")

        promises.push(
          APIPutFeatures({
            keycloak,
            device_sn,
            formData,
          }).then((response) => {
            if (response.status === 200) {
              let featureId = ""

              for (let i = 0; i <= response.data.length; i++) {
                if (response.data[i].name === value.name) {
                  featureId = response.data[i].id
                  break
                }
              }

              return APIPutFeaturesStatus({
                keycloak,
                device_sn: device_sn || "",
                featureId,
                featureStatus: value.enabled || false,
              })
            } else {
              enqueueSnackbar(response.data.message, {
                variant: "error",
              })
            }
          })
        )
      } else {
        // update active image status

        promises.push(
          APIPutFeaturesStatus({
            keycloak,
            device_sn: device_sn || "",
            featureId: value.id,
            featureStatus: value.enabled || false,
          })
        )
      }
    }

    Promise.all(promises).then((responses) => {
      responses.forEach((response) => {
        if (response) {
          if (response.status >= 300) {
            enqueueSnackbar(response.data.message, {
              variant: "error",
            })
            hasError = true
          }
        }
      })

      if (!hasError)
        enqueueSnackbar("Images successfully updated", { variant: "default" })
    })
  }

  const useButtonStyles = makeStyles({
    root: {
      "&:focus": {
        outline: "none",
      },
    },
  })
  const classesButton = useButtonStyles()

  return (
    <div className="w-full">
      <div>
        <Button
          style={{
            backgroundColor: customColors.orange,
            color: "white",
            borderRadius: "0",
            marginBottom: "-0.5rem",
            textTransform: "unset",
          }}
          classes={{ root: classesButton.root }}
          onClick={handleImagesSave}
        >
          Save settings
        </Button>
      </div>
      <table className="border-collapse">
        <thead>
          <tr className="bg-sidebar text-white">
            <th colSpan={3} className="bg-white">
              &nbsp;
            </th>
            <th
              colSpan={3}
              className="border border-black"
              style={{ padding: "6px 0" }}
            >
              User defined Image
            </th>
          </tr>
          <tr className="bg-sidebar text-white">
            <th className="border border-black p-2.5 w-20">Image</th>
            <th className="border border-black p-2.5 w-56">Default image</th>
            <th className="border border-black p-2.5 w-44">Active image</th>
            <th className="border border-black p-2.5 w-56">Image</th>
            <th className="border border-black p-2.5 w-20">Upload new image</th>
            <th className="border border-black p-2.5 w-20">Delete</th>
          </tr>
        </thead>
        <tbody>
          {imageNames.map((elem, index) => (
            <tr key={index}>
              <td className="border border-black p-2.5 text-center">
                {index + 1}
              </td>
              <td className="border border-black p-2.5">
                {defaultImages[namesToKeys[elem]] ? (
                  <img
                    src={defaultImages[namesToKeys[elem]].image}
                    alt={elem}
                  />
                ) : (
                  ""
                )}
              </td>
              <td className="border border-black p-2.5">
                <RadioButtonsGroup
                  imageName={elem}
                  selected={
                    deviceImagesForm[elem]?.enabled ? "custom" : "default"
                  }
                  handleActiveImage={handleActiveImage}
                  customDisabled={
                    deviceImagesForm[elem] === undefined ||
                    deviceImagesForm[elem].image === ""
                  }
                />
              </td>
              <td className="border border-black p-2.5">
                {deviceImagesForm[elem]?.image ? (
                  <img
                    src={deviceImagesForm[elem].image}
                    alt={`${elem} custom img`}
                  />
                ) : (
                  ""
                )}
              </td>
              <td className="border border-black p-2.5">
                <UploadButton
                  imageName={elem}
                  imageEnabled={deviceImagesForm[elem]?.enabled || false}
                  setDeviceImages={setDeviceImages}
                />
              </td>
              <td className="border border-black p-2.5">
                <DeleteButton
                  imageName={elem}
                  setDeviceImages={setDeviceImages}
                  disabled={!deviceImagesForm[elem]?.image}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
