import React, { Dispatch, SetStateAction, useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined"
import { ImageFeature } from "./ImageUpload"
import { makeStyles } from "@material-ui/core/styles"
import customColors from "../../../../tailwind.customColors.json"

export interface imageUploadProps {
  imageName: string
  imageEnabled: boolean
  setDeviceImages: Dispatch<SetStateAction<ImageFeature[]>>
}

export default function ImageUpload({
  imageName,
  imageEnabled,
  setDeviceImages,
}: imageUploadProps) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(false)
  const [file, setFile] = useState<File | undefined>()
  const [image, setImage] = useState<string | undefined>()

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    let reader = new window.FileReader()
    if (e.target.files) {
      reader.readAsDataURL(e.target.files[0])
      setFile(e.target.files[0])
      reader.onload = function () {
        if (typeof reader.result === "string") setImage(reader.result)
      }
    }
  }

  function handleSubmit() {
    if (image && file) {
      setDeviceImages((prevState) => {
        let temp = prevState.slice()
        let editIndex: number | undefined

        for (let i = 0; i < temp.length; i++) {
          if (temp[i].name === imageName) {
            editIndex = i
            break
          }
        }

        if (editIndex === undefined) {
          temp.push({
            image,
            name: imageName,
            id: "",
            enabled: imageEnabled,
            file,
          })
        } else {
          temp[editIndex] = {
            ...temp[editIndex],
            image,
            // id: "",
            file,
          }
        }

        return temp
      })

      setDialogOpened(false)
    }
  }

  const useButtonStyles = makeStyles({
    root: {
      backgroundColor: customColors.sidebar,
      "&:hover": {
        backgroundColor: customColors.sidebar,
      },
      "&$disabled": {
        backgroundColor: customColors.grey,
      },
      "&:focus": {
        outline: "none",
      },
    },
    disabled: {},
  })
  const classesButton = useButtonStyles()

  const useIconStyles = makeStyles({
    root: {
      "&:focus": {
        outline: "none",
      },
    },
  })
  const classesIcon = useIconStyles()

  return (
    <>
      <IconButton
        onClick={() => {
          setDialogOpened(true)
        }}
        classes={{ root: classesIcon.root }}
      >
        <CloudUploadOutlinedIcon fontSize="large" style={{ color: "black" }} />
      </IconButton>
      <Dialog
        open={dialogOpened}
        onClose={() => {
          setDialogOpened(false)
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Image Upload</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {image ? (
              <img alt="preview" src={image} />
            ) : (
              <span>Select the image you would like to be displayed.</span>
            )}
          </DialogContentText>
          <input type="file" onChange={(e) => handleSelect(e)} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpened(false)
            }}
            classes={{ root: classesButton.root }}
            style={{
              color: "white",
              borderRadius: "0",
              textTransform: "unset",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={image === undefined}
            autoFocus
            color="primary"
            classes={{
              root: classesButton.root,
              disabled: classesButton.disabled,
            }}
            style={{
              color: "white",
              borderRadius: "0",
              textTransform: "unset",
            }}
          >
            Upload Image
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
