import axios from "axios"
import Keycloak from "keycloak-js"
import { updateKeycloakToken } from "./APIGet"

export interface IAPIDelProps {
  TokenID?: string
  keycloak: Keycloak.KeycloakInstance
  groupID?: string | null
  device_sn?: string | null
}

export async function APITokenDel({ keycloak, TokenID }: IAPIDelProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const Token = authAxios.delete(`/api-keys/${TokenID}`)

  return Token.then((Token) => {
    return Token
  }).catch((err) => {
    return err.response
  })
}

export async function APIDelDeviceFromGroup({
  keycloak,
  device_sn,
  groupID,
}: IAPIDelProps) {
  updateKeycloakToken(keycloak)

  //FOR SOME Reason JS adds quotes to the device_sn variable so we have to remove them before using them in
  let string = JSON.stringify(device_sn)
  let no_quotes = string.replace(/['"\\]+/g, "")

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const Token = authAxios.delete(`/devices/${no_quotes}/groups/${groupID}`)
  console.log(Token)

  return Token.then((Token) => {
    return Token
  }).catch((err) => {
    return err.response
  })
}

export interface deleteFeatureProps {
  keycloak: Keycloak.KeycloakInstance
  device_sn: string
  featureId: string
}

export async function APIDelFeatureFromDevice({
  keycloak,
  device_sn,
  featureId,
}: deleteFeatureProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.delete(
    `/devices/${device_sn}/features/${featureId}`
  )

  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIDelGroup({ keycloak, groupID }: IAPIDelProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.delete(`/groups/${groupID}`)

  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}
