import React, { useEffect, useState } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { APISearchDevice } from "../APIRequests/APIGet"
import { Search } from "@material-ui/icons"
import Keycloak from "keycloak-js"
import { IDeviceInfo } from "../DeviceLayoutContent/Device"
import { useSnackbar } from "notistack"
import { CircularProgress } from "@material-ui/core"

interface SearchBarProps {
  keycloak: Keycloak.KeycloakInstance
  onSelect: (deviceInfo: IDeviceInfo) => void
  productFilter?: string | undefined
  disabledFilter?: string[]
  selectedDeviceInfo?: IDeviceInfo
  excludeDevice?: string
  scannedDevice?: string
}

export default function SearchBar({
  keycloak,
  onSelect,
  productFilter,
  disabledFilter,
  selectedDeviceInfo,
  excludeDevice,
  scannedDevice,
}: SearchBarProps) {
  const [options, setOptions] = useState<IDeviceInfo[]>([])
  const [searchString, setSearchString] = useState<string>("")
  const [delayInProgress, setDelayInProgress] = useState<boolean>(false)
  const [delayTimeoutId, setDelayTimeoutId] = useState<any>(null)
  const [searchValue, setSearchValue] = useState<IDeviceInfo | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!delayInProgress) {
      APISearchDevice({
        keycloak,
        search: searchString,
        product: productFilter,
      }).then((response) => {
        if (response.status === 200) {
          let results: []
          if (excludeDevice !== undefined) {
            results = response.data.results.filter(
              (elem: IDeviceInfo) => elem.sn !== excludeDevice
            )
          } else {
            results = response.data.results
          }
          setOptions(results)
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" })
        }
      })
    }
  }, [
    keycloak,
    enqueueSnackbar,
    searchString,
    productFilter,
    excludeDevice,
    delayInProgress,
  ])

  useEffect(() => {
    setSearchValue(selectedDeviceInfo ?? null)
  }, [selectedDeviceInfo])

  useEffect(() => {
    if (scannedDevice != null) {
      setSearchString(scannedDevice)
    } else {
      console.log("empty string")
    }
  }, [scannedDevice])

  function getLabel(option: IDeviceInfo) {
    let labelStr = option.sn
    if (option.alias && option.alias !== option.sn) {
      labelStr = `${option.alias} (${option.sn})`
    }
    return labelStr
  }

  function handleInputChange(value: string) {
    // disable search on item select
    let changedAfterSelect = false
    for (let i = 0; i < options.length; i++) {
      if (value === getLabel(options[i])) {
        changedAfterSelect = true
        break
      }
    }

    if (!changedAfterSelect) {
      delayTimeoutId !== null && clearTimeout(delayTimeoutId)
      setDelayInProgress(true)
      setDelayTimeoutId(window.setTimeout(() => setDelayInProgress(false), 500))
    }

    setSearchString(value)
  }

  function handleSelect(value: IDeviceInfo | null) {
    if (value) {
      setSearchValue(value)
      onSelect(value)
    }
  }

  return (
    <Autocomplete
      id="combo-box-demo"
      options={options}
      getOptionSelected={(option, value) => option.sn === value.sn}
      getOptionDisabled={(option) => {
        if (disabledFilter?.length)
          return disabledFilter.indexOf(option.sn) !== -1
        else return false
      }}
      getOptionLabel={(option) => getLabel(option)}
      style={{ width: 300 }}
      renderInput={(params) => (
        <div
          className="flex p-3 border border-sidebar justify-between bg-white"
          ref={params.InputProps.ref}
        >
          <input
            {...params.inputProps}
            className="focus:outline-none w-full pr-2"
            type="text"
            placeholder="Type MAC address"
            value={searchString}
          />
          {delayInProgress ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <Search />
          )}
        </div>
      )}
      value={searchValue}
      onInputChange={(e, value) => handleInputChange(value)}
      onChange={(e, value) => handleSelect(value)}
      clearOnEscape={true}
    />
  )
}
