import bgImage from "../../src/img/page_bg.jpg"

export default function HomePage() {
  return (
    <div className="flex flex-col h-full w-full bg-sidebar">
      <div className="flex h-full w-full">
        <div className="flex-shrink w-3/4">
          <img
            src={bgImage}
            alt="background"
            style={{
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="w-1/4 flex-none text-center px-4 text-white flex flex-col justify-between h-full">
          <div
            className="flex flex-col flex-grow gap-4 justify-center leading-snug antialiased"
            style={{
              fontFamily: "RobotoCondensed",
              fontSize: "1.2rem",
              minWidth: "265px",
            }}
          >
            <div
              className=""
              style={{
                color: "#3db7e4",
                fontSize: "3.5rem",
                fontWeight: "bold",
              }}
            >
              WELCOME
            </div>
            <div className="">
              <div>Our S-2CONNECT configurator,</div>
              <div>the tool for configuration</div>
              <div>of your S-2CONNECT devices.</div>
            </div>
            <div className="">
              <div>For more information about</div>
              <div>S-2CONNECT devices:</div>
              <div>
                <a
                  href="https://www.s-2connect.com"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#3db7e4" }}
                >
                  www.s-2connect.com
                </a>
              </div>
            </div>
            <div className="">
              <div>For more information about TT Electronics:</div>
              <div>
                <a
                  href="https://www.ttelectronics.com"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#3db7e4" }}
                >
                  www.ttelectronics.com
                </a>
              </div>
            </div>
          </div>
          <div className="text-center text-sm flex-none mb-4">
            {process.env.NODE_ENV === "development"
              ? "dev"
              : process.env.REACT_APP_VERSION ?? ""}
          </div>
        </div>
      </div>
    </div>
  )
}
