import { ReactNode, useState, ChangeEvent } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { AppBar, Tabs, Tab } from "@material-ui/core"
import customColors from "../../tailwind.customColors.json"

interface ITabsProps {
  tabsConfig: Map<string, ReactNode>
}

export default function DeviceType({ tabsConfig }: ITabsProps) {
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue)
  }

  const classesTab = useTabStyles()

  return (
    <div className="w-full">
      <AppBar
        position="relative"
        style={{ width: "fit-content", boxShadow: "none" }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          style={{ backgroundColor: "white" }}
          classes={{
            root: classesTab.rootFlexContainer,
            flexContainer: classesTab.flexContainer,
          }}
          TabIndicatorProps={{ style: { background: "none" } }}
        >
          {Array.from(tabsConfig).map(([key], rowIndex) => (
            <Tab
              key={key}
              label={key}
              {...a11yProps(rowIndex)}
              classes={{ root: classesTab.root, selected: classesTab.selected }}
            />
          ))}
        </Tabs>
      </AppBar>
      {Array.from(tabsConfig).map(([key, value], rowIndex) => (
        <TabPanel key={key} value={selectedTab} index={rowIndex}>
          {value}
        </TabPanel>
      ))}
    </div>
  )
}

interface TabPanelProps {
  children?: ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

const useTabStyles = makeStyles({
  root: {
    backgroundColor: "white",
    color: customColors.sidebar,
    textTransform: "unset",
    border: `1px solid ${customColors.sidebar}`,
    minHeight: "unset",
    padding: "3px 10px",
    "&:focus": {
      outline: "none",
    },
    "&$selected": {
      backgroundColor: customColors.sidebar,
      color: "white",
      "&:focus": {
        outline: "none",
      },
    },
  },
  selected: {},

  rootFlexContainer: { "& $flexContainer": { gap: "1px" } },
  flexContainer: {},
})
