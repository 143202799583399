import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import Keycloak from "keycloak-js"

// In order to initialize Keycloak JS client, we need to pass a config
// But since we want to be able to define ENV specific settings, we will
// construct that config as a JSON string, by fetching values from ENV vars
let keycloakConfig = {
  realm: process.env.REACT_APP_TTIOT_KEYCLOAK_REALM!,
  url: process.env.REACT_APP_TTIOT_KEYCLOAK_URL!,
  "ssl-required": "external",
  clientId: process.env.REACT_APP_TTIOT_KEYCLOAK_CLIENTID!,
  "public-client": true,
  "confidential-port": 0,
}

let keycloak = Keycloak(keycloakConfig)

keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
  localStorage.setItem(
    "Keycloak Object",
    JSON.stringify(keycloak.idTokenParsed)
  )
  if (!authenticated) {
    window.location.reload()
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <App keycloak={keycloak} />
      </React.StrictMode>,
      document.getElementById("root")
    )

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
  }
})
