import axios from "axios"
import Keycloak from "keycloak-js"
import { updateKeycloakToken } from "./APIGet"
import {
  INotification,
  notificationMessage,
} from "../DeviceLayoutContent/Device"

export interface IAPIPutProps {
  keycloak: Keycloak.KeycloakInstance
  device_sn?: string | null
  groups?: { id: string }[] | null
  formData?: FormData
  name?: string
}

export interface apiError {
  code: number
  message: string
}

export async function APIPutDeviceGroup({
  keycloak,
  device_sn,
  groups,
}: IAPIPutProps) {
  updateKeycloakToken(keycloak)

  //FOR SOME Reason JS adds quotes to the device_sn variable so we have to remove them before using them in
  let string = JSON.stringify(device_sn)
  let no_quotes = string.replace(/['"\\]+/g, "")
  //console.log(formData?.getAll('file'))

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.put(`devices/${no_quotes}/groups`, groups)
  //console.log("Get ALL Groups =====>", response)
  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIPutFeatures({
  keycloak,
  device_sn,
  formData,
}: IAPIPutProps) {
  updateKeycloakToken(keycloak)

  //FOR SOME Reason JS adds quotes to the device_sn variable so we have to remove them before using them in
  let string = JSON.stringify(device_sn)
  let no_quotes = string.replace(/['"\\]+/g, "")
  //console.log(formData?.getAll('file'))

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.put(`devices/${no_quotes}/features`, formData)
  //console.log("Get ALL Groups =====>", response)
  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

interface putStatusProps {
  keycloak: Keycloak.KeycloakInstance
  device_sn: string
  featureId: string
  featureStatus: boolean
}

export async function APIPutFeaturesStatus({
  keycloak,
  device_sn,
  featureId,
  featureStatus,
}: putStatusProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.put(`devices/${device_sn}/features/${featureId}`, {
    enabled: featureStatus,
  })
  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export async function APIPutAlias({ keycloak, device_sn, name }: IAPIPutProps) {
  updateKeycloakToken(keycloak)

  //FOR SOME Reason JS adds quotes to the device_sn variable so we have to remove them before using them in
  let string = JSON.stringify(device_sn)
  let no_quotes = string.replace(/['"\\]+/g, "")

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const Token = authAxios.put(`devices/${no_quotes}/alias`, { name: name })
  return Token.then((Token) => {
    return Token
  }).catch((err) => {
    return err.response
  })
}

interface IAPIPostConfigProps {
  device_sn: string
  config: IDeviceConfig | undefined
  keycloak: Keycloak.KeycloakInstance
}

export async function APIPutDeviceConfig({
  keycloak,
  device_sn,
  config,
}: IAPIPostConfigProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const Token = authAxios.put(`/devices/${device_sn}/config`, config)
  return Token.catch((err) => {
    return err.response
  }).then((response) => {
    return response
  })
}

interface putNotificationsPops {
  keycloak: Keycloak.KeycloakInstance
  device_sn: string
  notifications: {
    contacts: INotification[]
    messages: notificationMessage[]
  }
}

export async function APIPutNotifications({
  keycloak,
  device_sn,
  notifications,
}: putNotificationsPops) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.put(
    `devices/${device_sn}/notification-settings`,
    notifications
  )
  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

interface CopyFeaturesProps {
  keycloak: Keycloak.KeycloakInstance
  device_sn_from: string
  device_sn_to: string
}

export async function APIPutCopyFeatures({
  keycloak,
  device_sn_from,
  device_sn_to,
}: CopyFeaturesProps) {
  updateKeycloakToken(keycloak)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_TTIOT_API_URL,
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  })

  const response = authAxios.put(
    `/devices/${device_sn_to}/features?source=/v1/devices/${device_sn_from}/features`
  )
  return response
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err.response
    })
}

export interface IDeviceConfig {
  version?: number
  device_sn?: string
  device_type?: string
  bat?: {
    enable?: boolean
    highlim?: number
    lowlim?: number
    prio?: number

    schedule?: {
      invent?: number
      motion?: number
      station?: number
    }
  }
  btn?: {
    holdoff_time?: number
    t1?: number
    t2?: number
  }
  schedule?: {
    invent?: number
    motion?: number
    station?: number
  }
  server?: {
    apn_1?: string
    apn_2?: string
    port?: number
    server?: string
    ssl?: boolean
  }
  temp?: {
    enable: boolean
    lowlim: number
    highlim: number
    prio: number
    schedule: {
      motion?: number
      station?: number
      invent?: number
    }
  }
  hum?: {
    enable: boolean
    lowlim: number
    highlim: number
    prio: number
    schedule: {
      motion?: number
      station?: number
      invent?: number
    }
  }
  client?: {
    commwindow: number
    highspeed: boolean
    schedule: {
      invent: number
      motion: number
      station: number
    }
    taglist: string[]
  }
}
