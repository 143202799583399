import { IDeviceConfig } from "../../../APIRequests/APIPut"
import DevicePage from "../../DevicePage"

interface DeviceTabComponentProps {
  deviceConfig: IDeviceConfig
  saveConfig: (config: IDeviceConfig) => void
  configSchema: { [p: string]: any } | undefined
}

export default function Sensors({
  deviceConfig,
  saveConfig,
  configSchema,
}: DeviceTabComponentProps) {
  const formConfig: {} = {
    Schedule: ["schedule.motion", "schedule.station", "schedule.invent"],

    Battery: [
      "bat.enable",
      "bat.lowlim",
      "bat.highlim",
      "bat.prio",
      "bat.schedule.motion",
      "bat.schedule.station",
      "bat.schedule.invent",
    ],

    "Bluetooth client": ["client.commwindow", "client.schedule.motion"],

    GNSS: ["gnss.motion", "gnss.station", "gnss.invent"],
  }

  return (
    <DevicePage
      deviceConfig={deviceConfig}
      saveConfig={saveConfig}
      configSchema={configSchema}
      formConfig={formConfig}
    />
  )
}
