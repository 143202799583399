import {
  Checkbox,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
} from "@material-ui/core"
import { ChangeEvent } from "react"
import { makeStyles } from "@material-ui/core/styles"
import customColors from "../../tailwind.customColors.json"
import { DeviceFormState } from "./DevicePage"
import { deviceConfigData } from "./deviceConfigData"

interface ConfigBlockProps {
  title: string
  values: string[]
  formValues: DeviceFormState
  handleChange: (event: ChangeEvent<any>, propName: string) => void
  formErrors: Map<string, string>
}

export default function ConfigBlock({
  title,
  values,
  formValues,
  handleChange,
  formErrors,
}: ConfigBlockProps) {
  let checkboxKey: string | undefined

  values.forEach((dataKey) => {
    if (deviceConfigData[dataKey].type === "checkbox") {
      checkboxKey = dataKey
    }
  })

  const useStylesCh = makeStyles({
    root: {
      "&:hover": {
        background: "none",
      },
      "&$checked": {
        "&:hover": {
          background: "none",
        },
      },
      "& svg": {
        fontSize: "1.8rem",
      },
    },
    checked: {},
  })
  const classesCh = useStylesCh()

  const useStylesInput = makeStyles({
    root: {
      borderRadius: 0,
      border: `1px solid ${customColors.sidebar}`,
      backgroundColor: "white",
      "&:focus": { outline: "none" },
      "& fieldset": { border: "none" },
      "&$error": {
        border: `1px solid ${customColors.danger}`,
        color: "black",
      },
    },
    error: {},
  })
  const classesInput = useStylesInput()

  const useStylesDivInput = makeStyles({
    root: {
      width: "11rem",
      "& .MuiFormHelperText-root.Mui-error": {
        color: customColors.danger,
        marginLeft: "2px",
        marginRight: "2px",
      },
    },
  })
  const classesDivInput = useStylesDivInput()

  const useStylesSelect = makeStyles({
    root: {
      "&:focus": { outline: "none" },
      "& fieldset": { border: "none" },
    },
  })
  const classesSelect = useStylesSelect()

  return (
    <div
      className="w-72 bg-popper px-3 pt-1 pb-2"
      style={{ height: "fit-content" }}
    >
      <div
        className="flex justify-between items-center"
        style={{ height: "42px" }}
      >
        <div
          className="w-36 border border-sidebar bg-customGrey text-white text-center"
          style={{ height: "fit-content" }}
        >
          {title}
        </div>
        {checkboxKey !== undefined && (
          <Checkbox
            onChange={(e) => handleChange(e, checkboxKey!)}
            checked={formValues[checkboxKey] as boolean}
            classes={{ root: classesCh.root, checked: classesCh.checked }}
            style={{
              color: customColors.sidebar,
              padding: "0",
              marginRight: "-3px",
            }}
          />
        )}
      </div>
      {values.map((dataKey) => (
        <div className="flex justify-between mt-4" key={dataKey}>
          <div className="w-32 leading-tight flex flex-col justify-center">
            <div>{deviceConfigData[dataKey].title?.text ?? ""}</div>
            {deviceConfigData[dataKey].title?.unit && (
              <div className="text-sm">
                ({deviceConfigData[dataKey].title!.unit})
              </div>
            )}
          </div>
          {deviceConfigData[dataKey].type === "text" && (
            <TextField
              type="text"
              variant="outlined"
              disabled={deviceConfigData[dataKey].readOnly}
              value={formValues[dataKey]}
              error={formErrors.has(dataKey)}
              helperText={formErrors.get(dataKey) || ""}
              classes={{ root: classesDivInput.root }}
              InputProps={{
                classes: {
                  root: classesInput.root,
                  error: classesInput.error,
                },
              }}
              inputProps={{
                style: { textAlign: "center", padding: "0.5rem" },
              }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange(e, dataKey)}
            />
          )}
          {deviceConfigData[dataKey].type === "select" && (
            <Select
              variant="outlined"
              value={formValues[dataKey]}
              onChange={(e) => handleChange(e, dataKey)}
              style={{
                borderRadius: 0,
                border: `1px solid ${customColors.sidebar}`,
                width: "11rem",
              }}
              SelectDisplayProps={{
                style: {
                  padding: "0.5rem 2rem 0.5rem 0.5rem",
                  backgroundColor: "white",
                },
              }}
              input={<OutlinedInput classes={{ root: classesSelect.root }} />}
            >
              {deviceConfigData[dataKey].dataSet?.map((value, i) => (
                <MenuItem value={value[0]} key={i}>
                  {value[1]}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      ))}
    </div>
  )
}
