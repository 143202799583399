import LayoutRoutes from "./Components/Layouts/LayoutRoutes"
import { useEffect, useState } from "react"
import Keycloak from "keycloak-js"
import { SnackbarProvider } from "notistack"
import { IDeviceInfo } from "./Components/DeviceLayoutContent/Device"

interface AppProps {
  keycloak: Keycloak.KeycloakInstance
}

function App({ keycloak }: AppProps) {
  const [selectedDeviceInfo, setSelectedDeviceInfo] = useState<IDeviceInfo>()
  const [selectedMenu, setSelectedMenu] = useState<string>(
    document.location.pathname.split("/")[1] || "home"
  )

  useEffect(() => {
    if (selectedMenu !== "configurator") setSelectedDeviceInfo(undefined)
  }, [selectedMenu])

  return (
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      preventDuplicate={true}
    >
      <LayoutRoutes
        keycloak={keycloak}
        selectedDeviceInfo={selectedDeviceInfo}
        setSelectedDeviceInfo={setSelectedDeviceInfo}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      />
    </SnackbarProvider>
  )
}

export default App
