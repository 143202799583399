import { KeycloakInstance } from "keycloak-js"
import { IDeviceConfig } from "../../APIRequests/APIPut"
import React, { Dispatch, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Close, DeleteOutline } from "@material-ui/icons"
import { Button, IconButton } from "@material-ui/core"
import { IDeviceInfo } from "../Device"
import customColors from "../../../tailwind.customColors.json"
import SearchBar from "../../DeviceHeader/SearchBar"
import { Html5QrcodeScanner } from "html5-qrcode"

import {
  Html5QrcodeError,
  Html5QrcodeResult,
  Html5QrcodeSupportedFormats,
} from "html5-qrcode/esm/core"

interface Props {
  keycloak: KeycloakInstance
  deviceConfig: IDeviceConfig | undefined
  saveConfig: (config: IDeviceConfig) => void
  setCompanionsOpened: Dispatch<boolean>
  deviceInfo: IDeviceInfo
}

export default function CompanionsSubMenu({
  keycloak,
  deviceConfig,
  saveConfig,
  setCompanionsOpened,
  deviceInfo,
}: Props) {
  const [tagList, setTagList] = useState<string[]>([])
  const [newTag, setNewTag] = useState<string>("")
  const [deviceScanned, setDeviceScanned] = useState<string>("")
  useEffect(() => {
    const tagListTemp = deviceConfig?.client?.taglist ?? []

    setTagList(tagListTemp)
  }, [deviceConfig])

  const aliasAndSn =
    deviceInfo.alias !== deviceInfo.sn
      ? `${deviceInfo.alias} (${deviceInfo.sn})`
      : deviceInfo.sn

  function handleDeleteClick(index: number) {
    if (deviceConfig?.client) {
      const tagListTemp = [...tagList]
      tagListTemp.splice(index, 1)
      const deviceConfigTemp = {
        ...deviceConfig,
        client: {
          ...deviceConfig.client,
          taglist: tagListTemp,
        },
      }

      saveConfig(deviceConfigTemp)
    }
  }

  function handleAddClick() {
    if (newTag && deviceConfig?.client) {
      const tagListTemp = [...tagList, newTag]
      const deviceConfigTemp = {
        ...deviceConfig,
        client: {
          ...deviceConfig.client,
          taglist: tagListTemp,
        },
      }
      saveConfig(deviceConfigTemp)
      setNewTag("")
    }
  }

  function handleScanner() {
    const onScanSuccess = (
      decodedText: string,
      decodedResult: Html5QrcodeResult
    ) => {
      // handle the scanned code as you like
      decodedText = decodedText.substring(0, 17).toLowerCase()
      setDeviceScanned(decodedText)
    }

    const onScanFailure = (errorMessage: string, error: Html5QrcodeError) => {
      // handle scan failure, usually better to ignore and keep scanning
      console.warn(`Code scan error = ${errorMessage}`, error)
    }

    const formatsToSupport = [Html5QrcodeSupportedFormats.QR_CODE]

    let qrboxFunction = function (
      viewfinderWidth: number,
      viewfinderHeight: number
    ) {
      console.log(`w: ${viewfinderWidth},h: ${viewfinderHeight}`)
      let minEdgePercentage = 0.7 // 70%
      let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight)
      let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage)
      return {
        width: qrboxSize,
        height: qrboxSize,
      }
    }

    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      {
        fps: 10,
        qrbox: qrboxFunction,
        rememberLastUsedCamera: true,
        aspectRatio: 4 / 3,
        showTorchButtonIfSupported: true,
        showZoomSliderIfSupported: true,
        defaultZoomValueIfSupported: 2,
        formatsToSupport: formatsToSupport,
      },
      false
    )
    html5QrcodeScanner.render(onScanSuccess, onScanFailure)
  }

  const useStylesBtn = makeStyles({
    root: {
      backgroundColor: customColors.orange,
      "&:focus": { outline: "none" },
      "&:hover": { backgroundColor: customColors.orange },
      "&$disabled": {
        backgroundColor: customColors.grey,
        color: customColors.popper,
      },
    },
    disabled: {},
  })
  const classesBtn = useStylesBtn()

  const useIconStyles = makeStyles({
    root: {
      color: "black",
      padding: "7px",
      "&:focus": {
        outline: "none",
      },
    },
  })
  const classesIcon = useIconStyles()

  return (
    <div
      className="border-8 border-sidebar flex flex-col"
      style={{ minWidth: "400px" }}
    >
      <div className="flex justify-between bg-popper">
        <div
          className="w-36 border border-sidebar bg-customGrey text-white text-center"
          style={{ height: "fit-content", margin: "0.5rem 20px" }}
        >
          Tags for {aliasAndSn}
        </div>
        <Close
          style={{ fontSize: "30px", cursor: "pointer" }}
          onClick={() => setCompanionsOpened(false)}
        />
      </div>
      <div
        className="flex flex-col gap-4 bg-popper"
        style={{ padding: "10px 40px 20px 20px" }}
      >
        <table className="border border-black w-full border-collapse">
          <thead>
            <tr className="bg-sidebar text-white">
              <td className="w-72 border border-black p-2 text-center">Tags</td>
              <td className="border border-black p-2 text-center">Delete</td>
            </tr>
          </thead>
          <tbody>
            {tagList.length === 0 ? (
              <tr className="bg-white">
                <td className="border border-black p-2">&nbsp;</td>
                <td className="border border-black p-2">&nbsp;</td>
              </tr>
            ) : (
              tagList.map((elem, index) => (
                <tr className="bg-white" key={index}>
                  <td className="border border-black px-2">{elem}</td>
                  <td className="border border-black px-2 text-center">
                    <IconButton
                      classes={{ root: classesIcon.root }}
                      onClick={() => handleDeleteClick(index)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="flex">
          <SearchBar
            keycloak={keycloak}
            onSelect={(deviceInfo) => {
              setNewTag(deviceInfo.sn)
            }}
            productFilter="s22z"
            disabledFilter={tagList}
            scannedDevice={deviceScanned}
          />
          <Button
            style={{
              color: "white",
              borderRadius: 0,
              marginLeft: "20px",
              textTransform: "unset",
            }}
            classes={{ root: classesBtn.root, disabled: classesBtn.disabled }}
            onClick={handleAddClick}
            disabled={newTag === ""}
          >
            Add
          </Button>
          <Button
            style={{
              color: "white",
              borderRadius: 0,
              marginLeft: "20px",
              textTransform: "unset",
            }}
            classes={{ root: classesBtn.root, disabled: classesBtn.disabled }}
            onClick={handleScanner}
          >
            Scan
          </Button>
        </div>
        <div id="reader"></div>
      </div>
    </div>
  )
}
