import { Dispatch, SetStateAction, useState } from "react"
import { DeleteOutline } from "@material-ui/icons"
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core"
import customColors from "../../../../tailwind.customColors.json"
import { makeStyles } from "@material-ui/core/styles"
import { ImageFeature } from "./ImageUpload"

interface deleteButtonProps {
  imageName: string
  setDeviceImages: Dispatch<SetStateAction<ImageFeature[]>>
  disabled: boolean
}

export default function DeleteButton({
  imageName,
  setDeviceImages,
  disabled,
}: deleteButtonProps) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(false)

  function handleConfirmDelete() {
    setDeviceImages((prevState) => {
      let temp = prevState.slice()
      let deleteIndex = 0

      for (let i = 0; i < temp.length; i++) {
        if (temp[i].name === imageName) {
          deleteIndex = i
          break
        }
      }

      temp[deleteIndex].image = ""
      temp[deleteIndex].file = undefined
      temp[deleteIndex].enabled = false
      return temp
    })
    setDialogOpened(false)
  }

  const useButtonStyles = makeStyles({
    root: {
      "&:focus": {
        outline: "none",
      },
    },
  })
  const classesButton = useButtonStyles()

  const useIconStyles = makeStyles({
    root: {
      color: "black",
      "&:focus": {
        outline: "none",
      },
    },
  })
  const classesIcon = useIconStyles()

  return (
    <>
      <IconButton
        classes={{ root: classesIcon.root }}
        disabled={disabled}
        onClick={() => {
          setDialogOpened(true)
        }}
      >
        <DeleteOutline fontSize="large" />
      </IconButton>
      <Dialog
        open={dialogOpened}
        onClose={() => {
          setDialogOpened(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Image Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the uploaded image?
            <br />
            Once the image is deleted, the device will be switched to default
            image.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpened(false)
            }}
            classes={{ root: classesButton.root }}
            style={{
              backgroundColor: customColors.sidebar,
              color: "white",
              borderRadius: "0",
              textTransform: "unset",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            autoFocus
            classes={{ root: classesButton.root }}
            style={{
              backgroundColor: customColors.sidebar,
              color: "white",
              borderRadius: "0",
              textTransform: "unset",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
