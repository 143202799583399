import Keycloak from "keycloak-js"
import React, { Dispatch, useState } from "react"
import { IDeviceInfo } from "../Device"
import { Close } from "@material-ui/icons"
import { APIPutAlias } from "../../APIRequests/APIPut"
import customColors from "../../../tailwind.customColors.json"
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useSnackbar } from "notistack"

interface IAliasSubMenuProps {
  keycloak: Keycloak.KeycloakInstance
  setDeviceInfo: Dispatch<IDeviceInfo>
  deviceInfo: IDeviceInfo
  setAliasOpened: Dispatch<boolean>
}

export default function AliasSubMenu({
  keycloak,
  setDeviceInfo,
  deviceInfo,
  setAliasOpened,
}: IAliasSubMenuProps) {
  const [aliasTemp, setAliasTemp] = useState<string>(deviceInfo.alias)

  const { enqueueSnackbar } = useSnackbar()

  function handleSave() {
    APIPutAlias({
      keycloak: keycloak,
      device_sn: deviceInfo.sn,
      name: aliasTemp,
    }).then((response) => {
      if (response.status === 200) {
        setAliasOpened(false)
        setDeviceInfo(response.data)
        enqueueSnackbar("Device alias successfully updated", {
          variant: "default",
        })
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" })
      }
    })
  }

  const useStyles = makeStyles({
    rootBtn: {
      "&:focus": { outline: "none" },
    },
  })
  const classes = useStyles()

  return (
    <div
      className="border-8 border-sidebar flex flex-col"
      style={{ minWidth: "400px" }}
    >
      <div className="flex-none text-right bg-popper">
        <Close
          style={{ fontSize: "30px", cursor: "pointer" }}
          onClick={() => setAliasOpened(false)}
        />
      </div>
      <div
        className="flex-grow flex justify-between bg-popper"
        style={{ padding: "10px 40px 20px 20px" }}
      >
        <div className="flex p-3 border border-sidebar bg-white w-full">
          <input
            className="focus:outline-none w-full"
            type="text"
            placeholder="New alias"
            value={aliasTemp}
            onChange={(e) => setAliasTemp(e.target.value)}
          />
        </div>
        <Button
          style={{
            backgroundColor: customColors.orange,
            color: "white",
            borderRadius: 0,
            marginLeft: "20px",
          }}
          classes={{ root: classes.rootBtn }}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
