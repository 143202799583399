import { IDeviceConfig } from "../../APIRequests/APIPut"
import DevicePage, { DeviceFormConfig } from "../DevicePage"

interface Props {
  deviceConfig: IDeviceConfig
  saveConfig: (config: IDeviceConfig) => void
  configSchema: { [propName: string]: any } | undefined
}

export default function SenseDevice({
  deviceConfig,
  saveConfig,
  configSchema,
}: Props) {
  const formConfig: DeviceFormConfig = {
    Temperature: ["temp.enable", "temp.lowlim", "temp.highlim"],
    Humidity: ["hum.enable", "hum.lowlim", "hum.highlim"],
    Accelerometer: ["accel.threshold", "accel.duration", "accel.range"],
    Light: ["light.enable", "light.lowlim", "light.highlim"],
    Battery: ["bat.enable", "bat.lowlim", "bat.highlim"],
  }

  return (
    <DevicePage
      deviceConfig={deviceConfig}
      saveConfig={saveConfig}
      configSchema={configSchema}
      formConfig={formConfig}
    />
  )
}
