import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import ImageUpload, { ImageFeature } from "./Images/ImageUpload"
import Notifications from "./Notifications/Notifications"
import { IDeviceInfo, INotification, notificationMessage } from "../Device"
import Keycloak from "keycloak-js"
import Timers from "./Timers/Timers"
import { apiError, IDeviceConfig } from "../../APIRequests/APIPut"
import DeviceType from "../DeviceType"
import {
  apiFeature,
  APIGetAPIFeatures,
  APIGetDeviceFeatures,
  APIGetProductFeatures,
} from "../../APIRequests/APIGet"
import { AxiosPromise } from "axios"
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from "notistack"

interface ITabsProps {
  keycloak: Keycloak.KeycloakInstance
  selectedDeviceInfo: IDeviceInfo
  deviceConfig: IDeviceConfig
  notifications: INotification[]
  setNotifications: Dispatch<SetStateAction<INotification[]>>
  saveConfig: (config: IDeviceConfig) => void
  deviceMessages: notificationMessage[]
  setDeviceMessages: Dispatch<SetStateAction<notificationMessage[]>>
  configSchema: { [propName: string]: any } | undefined
}

export default function PressDevice({
  keycloak,
  selectedDeviceInfo,
  deviceConfig,
  notifications,
  setNotifications,
  saveConfig,
  deviceMessages,
  setDeviceMessages,
  configSchema,
}: ITabsProps) {
  const [defaultImages, setDefaultImages] = useState<ImageFeature[]>([])
  const [deviceImages, setDeviceImages] = useState<ImageFeature[]>([])

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    getImages(
      keycloak,
      APIGetProductFeatures({
        keycloak,
        product: selectedDeviceInfo.product,
      }),
      setDefaultImages,
      enqueueSnackbar
    )

    getImages(
      keycloak,
      APIGetDeviceFeatures({ keycloak, device_sn: selectedDeviceInfo.sn }),
      setDeviceImages,
      enqueueSnackbar
    )
  }, [
    selectedDeviceInfo.product,
    selectedDeviceInfo.sn,
    enqueueSnackbar,
    keycloak,
  ])

  let tabsConfig = new Map<string, ReactNode>()

  tabsConfig
    .set(
      "Images",
      <ImageUpload
        keycloak={keycloak}
        deviceImages={deviceImages}
        setDeviceImages={setDeviceImages}
        device_sn={selectedDeviceInfo.sn}
        defaultImages={defaultImages}
      />
    )
    .set(
      "Receivers",
      <Notifications
        keycloak={keycloak}
        device_sn={selectedDeviceInfo.sn}
        notifications={notifications}
        setNotifications={setNotifications}
        deviceMessages={deviceMessages}
        setDeviceMessages={setDeviceMessages}
      />
    )
    .set(
      "Timers etc",
      <Timers
        deviceConfig={deviceConfig}
        saveConfig={saveConfig}
        configSchema={configSchema}
      />
    )

  return <DeviceType tabsConfig={tabsConfig} />
}

export function getImages(
  keycloak: Keycloak.KeycloakInstance,
  promise: AxiosPromise<apiFeature[] | apiError>,
  setStateFunc: (arr: ImageFeature[]) => void,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey
) {
  promise.then((productFeatureResponse) => {
    if (
      productFeatureResponse.status === 200 &&
      Array.isArray(productFeatureResponse.data)
    ) {
      let promises: any[] = []
      productFeatureResponse.data.forEach((elem) => {
        if (elem.type === "image") {
          promises.push(
            APIGetAPIFeatures({
              keycloak: keycloak,
              id: elem.id,
              imageFeature: elem,
            })
          )
        }
      })
      Promise.all(promises).then((responseArr) => {
        if (responseArr) {
          Promise.all<ImageFeature>(
            responseArr.map((response) => {
              return new Promise((resolve, reject) => {
                let reader = new window.FileReader()
                reader.readAsDataURL(response.data)
                reader.onload = function () {
                  const imageObj: ImageFeature = {
                    ...response.forwardedImageData,
                    image: reader.result,
                  }
                  resolve(imageObj)
                }
                reader.onerror = function (error) {
                  reject(error)
                }
              })
            })
          ).then((result) => {
            let temp: ImageFeature[] = []
            result.forEach((elem) => {
              temp.push({
                name: elem.name,
                id: elem.id,
                image: elem.image,
                enabled: elem.enabled,
              })
            })
            setStateFunc(temp)
          })
        }
      })
    } else {
      if ("message" in productFeatureResponse.data) {
        enqueueSnackbar(
          "Error while loading device images: " +
            productFeatureResponse.data.message,
          {
            variant: "error",
          }
        )
      }
    }
  })
}
