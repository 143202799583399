import { Dispatch, SetStateAction } from "react"
import { useHistory } from "react-router-dom"
import { IDeviceInfo } from "./Device"

interface Props {
  deviceList: IDeviceInfo[]
  setSelectedDeviceInfo: Dispatch<SetStateAction<IDeviceInfo | undefined>>
  productType: { [propName: string]: string }
  nextPageToken: string
  handleLoadMore: () => void
}

export default function DeviceListTable({
  deviceList,
  setSelectedDeviceInfo,
  productType,
  nextPageToken,
  handleLoadMore,
}: Props) {
  let history = useHistory()

  function handleClick(deviceInfo: IDeviceInfo) {
    setSelectedDeviceInfo(deviceInfo)
    history.push(`/config/${deviceInfo.sn}`)
  }

  const lmStyles =
    nextPageToken === ""
      ? {
          color: "rgba(0, 0, 0, 0.38)",
          cursor: "default",
        }
      : { color: "rgba(0, 0, 0, 1)", cursor: "pointer" }

  return (
    <div>
      <table className="border-collapse border border-sidebar">
        <thead>
          <tr>
            <td className="border border-sidebar bg-sidebar text-white text-center p-2 w-44">
              Alias
            </td>
            <td className="border border-sidebar bg-sidebar text-white text-center p-2 w-44">
              Serial number
            </td>
            <td className="border border-sidebar bg-sidebar text-white text-center p-2 w-44">
              Type
            </td>
            <td className="border border-sidebar bg-sidebar text-white text-center p-2 w-44">
              Groups
            </td>
          </tr>
        </thead>
        <tbody>
          {deviceList.map((elem, index) => (
            <tr key={index}>
              <td
                className="border border-sidebar p-2.5 underline cursor-pointer"
                onClick={() => handleClick(elem)}
              >
                {elem.alias}
              </td>
              <td
                className="border border-sidebar p-2.5 underline cursor-pointer"
                onClick={() => handleClick(elem)}
              >
                {elem.sn}
              </td>
              <td className="border border-sidebar p-2.5">
                {productType[elem.product] ?? elem.product}
              </td>
              <td className="border border-sidebar p-2.5 text-center">
                {elem.groups && (
                  <>
                    {elem.groups.map((group, groupIndex) => (
                      <div className="w-max" key={groupIndex}>
                        {group.name}
                      </div>
                    ))}
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="text-center mt-4 cursor-pointer underline"
        style={lmStyles}
        onClick={nextPageToken ? handleLoadMore : () => {}}
      >
        Load more
      </div>
    </div>
  )
}
