import { Dispatch, SetStateAction } from "react"
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import { Link } from "react-router-dom"
import {
  Home,
  Group,
  FileCopy,
  Settings,
  AccountCircle,
  ExitToApp,
  HelpOutline,
} from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import customColors from "../../tailwind.customColors.json"
import { KeycloakInstance } from "keycloak-js"

interface ISideBarSectionsProps {
  selectedMenu: string
  setSelectedMenu: Dispatch<SetStateAction<string>>
  keycloak: KeycloakInstance
}

export default function SideBarSections({
  selectedMenu,
  setSelectedMenu,
  keycloak,
}: ISideBarSectionsProps) {
  // const [configSubMenuOpened, setConfigSubMenuOpened] = useState<boolean>(true)

  function handleListItemClick(index: string) {
    setSelectedMenu(index)
  }

  // function handleExpandConfig(state: boolean) {
  //   setConfigSubMenuOpened(state)
  // }

  const useStyles = makeStyles({
    root: {
      backgroundColor: customColors.sidebar,
      "&$selected": {
        backgroundColor: customColors.orange,
        "&:hover": {
          backgroundColor: customColors.orange,
        },
      },
    },
    selected: {},
    nested: {
      paddingLeft: "3rem",
    },
  })
  const classes = useStyles()

  return (
    <div className="h-full text-white py-2 flex flex-col justify-between">
      <List component="nav">
        <Link to="/" style={{ display: "block", marginBottom: "1rem" }}>
          <ListItem
            component="nav"
            classes={{ root: classes.root, selected: classes.selected }}
            button
            selected={selectedMenu === "home"}
            onClick={() => handleListItemClick("home")}
          >
            <ListItemIcon>
              <div className={"w-20"}>
                <Home style={{ color: "white", fontSize: 50 }} />
              </div>
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </Link>

        <Link to="/groupMgm" style={{ display: "block", marginBottom: "1rem" }}>
          <ListItem
            classes={{ root: classes.root, selected: classes.selected }}
            button
            selected={selectedMenu === "groupMgm"}
            onClick={() => handleListItemClick("groupMgm")}
          >
            <ListItemIcon>
              <div className={"w-20"}>
                <Group style={{ color: "white", fontSize: 50 }} />
              </div>
            </ListItemIcon>
            <ListItemText primary="Group Management" />
          </ListItem>
        </Link>

        <Link to="/copy" style={{ display: "block", marginBottom: "1rem" }}>
          <ListItem
            classes={{ root: classes.root, selected: classes.selected }}
            button
            selected={selectedMenu === "copy"}
            onClick={() => handleListItemClick("copy")}
          >
            <ListItemIcon>
              <div className={"w-20"}>
                <FileCopy style={{ color: "white", fontSize: 50 }} />
              </div>
            </ListItemIcon>
            <ListItemText primary="Copy" />
          </ListItem>
        </Link>

        <Link to="/config" style={{ display: "block" }}>
          <ListItem
            classes={{ root: classes.root, selected: classes.selected }}
            button
            selected={selectedMenu === "config"}
            onClick={() => handleListItemClick("config")}
          >
            <ListItemIcon>
              <div className={"w-20"}>
                <Settings style={{ color: "white", fontSize: 50 }} />
              </div>
            </ListItemIcon>
            <ListItemText primary="Configurator" />
          </ListItem>
        </Link>
      </List>

      <List component="nav">
        <Link to="/help" style={{ display: "block", marginBottom: "1rem" }}>
          <ListItem
            classes={{ root: classes.root, selected: classes.selected }}
            button
            selected={selectedMenu === "help"}
            onClick={() => handleListItemClick("help")}
          >
            <ListItemIcon>
              <div className={"w-20"}>
                <HelpOutline style={{ color: "white", fontSize: 50 }} />
              </div>
            </ListItemIcon>
            <ListItemText primary="Help" />
          </ListItem>
        </Link>

        <Link to="/profile" style={{ display: "block", marginBottom: "1rem" }}>
          <ListItem
            classes={{ root: classes.root, selected: classes.selected }}
            button
            selected={selectedMenu === "profile"}
            onClick={() => handleListItemClick("profile")}
          >
            <ListItemIcon>
              <div className={"w-20"}>
                <AccountCircle style={{ color: "white", fontSize: 50 }} />
              </div>
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
        </Link>

        <Link to="/logout" style={{ display: "block" }}>
          <ListItem
            classes={{ root: classes.root, selected: classes.selected }}
            button
            selected={selectedMenu === "logout"}
            onClick={() =>
              keycloak.logout({ redirectUri: document.location.origin })
            }
          >
            <ListItemIcon>
              <div className={"w-20"}>
                <ExitToApp style={{ color: "white", fontSize: 50 }} />
              </div>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Link>
      </List>
    </div>
  )
}
