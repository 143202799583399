export interface DeviceFormConfigRow {
  [p: string]: {
    default: number | string | boolean
    divider?: number
    multiplier?: number
    title?: { text: string; unit: string }
    type: "text" | "select" | "checkbox"
    dataSet?: [number, string][]
    readOnly?: boolean
    chBoxConfig?: {
      value: (value: any) => boolean
      dependedField: string
      valueToUpdate: (value: boolean) => number | string | boolean
    }
    dependedProp?: [
      string,
      (data: number | string | boolean) => number | string | boolean
    ]
  }
}

export const prioDataSet: [number, string][] = [
  [0, "Emergency"],
  [1, "Immediate"],
  [2, "Next call"],
]

export const rangeDataSet: [number, string][] = [
  [0, "2G"],
  [1, "4G"],
  [2, "8G"],
  [3, "16G"],
]

export const deviceConfigData: DeviceFormConfigRow = {
  // schedule

  "schedule.motion": {
    default: "0",
    title: { text: "Motion", unit: "Seconds" },
    type: "text",
  },
  "schedule.station": {
    default: "0",
    title: { text: "Stationary", unit: "Seconds" },
    type: "text",
  },
  "schedule.station#press": {
    default: "0",
    divider: 3600,
    title: { text: "Interval", unit: "Hour" },
    type: "text",
    dependedProp: [
      "schedule.station#pressDisabled",
      (data) => (data as number) * 3600,
    ],
  },
  "schedule.station#pressDisabled": {
    default: "0",
    title: { text: "Stationary", unit: "Seconds" },
    type: "text",
    readOnly: true,
  },
  "schedule.invent": {
    default: "0",
    title: { text: "Inventory", unit: "Seconds" },
    type: "text",
  },

  // bat

  "bat.enable": {
    type: "checkbox",
    default: false,
  },
  "bat.lowlim": {
    default: "0",
    divider: 100,
    title: { text: "Low", unit: "Volt" },
    type: "text",
  },
  "bat.highlim": {
    default: "0",
    divider: 100,
    title: { text: "High", unit: "Volt" },
    type: "text",
  },
  "bat.prio": {
    default: 0,
    title: { text: "Priority", unit: "" },
    type: "select",
    dataSet: prioDataSet,
  },
  "bat.schedule.motion": {
    default: "0",
    title: { text: "Motion", unit: "Seconds" },
    type: "text",
    readOnly: false,
  },
  "bat.schedule.station": {
    default: "0",
    title: { text: "Stationary", unit: "Seconds" },
    type: "text",
  },
  "bat.schedule.invent": {
    default: "0",
    title: { text: "Inventory", unit: "Seconds" },
    type: "text",
    readOnly: false,
  },

  // temp

  "temp.enable": {
    type: "checkbox",
    default: false,
  },
  "temp.lowlim": {
    default: "0",
    divider: 100,
    title: { text: "Low", unit: "°C" },
    type: "text",
  },
  "temp.highlim": {
    default: "0",
    divider: 100,
    title: { text: "High", unit: "°C" },
    type: "text",
  },
  "temp.prio": {
    default: 0,
    title: { text: "Priority", unit: "" },
    type: "select",
    dataSet: prioDataSet,
  },
  "temp.schedule.motion": {
    default: "0",
    title: { text: "Motion", unit: "Seconds" },
    type: "text",
  },
  "temp.schedule.station": {
    default: "0",
    title: { text: "Stationary", unit: "Seconds" },
    type: "text",
  },
  "temp.schedule.invent": {
    default: "0",
    title: { text: "Inventory", unit: "Seconds" },
    type: "text",
  },

  // hum

  "hum.enable": {
    type: "checkbox",
    default: false,
  },
  "hum.lowlim": {
    default: "0",
    divider: 100,
    title: { text: "Low", unit: "RH%" },
    type: "text",
  },
  "hum.highlim": {
    default: "0",
    divider: 100,
    title: { text: "High", unit: "RH%" },
    type: "text",
  },
  "hum.prio": {
    default: 0,
    title: { text: "Priority", unit: "" },
    type: "select",
    dataSet: prioDataSet,
  },
  "hum.schedule.motion": {
    default: "0",
    title: { text: "Motion", unit: "Seconds" },
    type: "text",
  },
  "hum.schedule.station": {
    default: "0",
    title: { text: "Stationary", unit: "Seconds" },
    type: "text",
  },
  "hum.schedule.invent": {
    default: "0",
    title: { text: "Inventory", unit: "Seconds" },
    type: "text",
  },

  // accel

  "accel.threshold": {
    default: "0",
    divider: 100,
    title: { text: "Threshold", unit: "m/s²" },
    type: "text",
  },
  "accel.duration": {
    default: "0",
    title: { text: "Duration", unit: "msec" },
    type: "text",
  },
  "accel.range": {
    default: 0,
    title: { text: "Range", unit: "" },
    type: "select",
    dataSet: rangeDataSet,
  },
  "accel.prio": {
    default: 0,
    title: { text: "Priority", unit: "" },
    type: "select",
    dataSet: prioDataSet,
  },

  // client

  "client.commwindow": {
    default: "0",
    title: { text: "Com Window", unit: "sec" },
    type: "text",
  },
  "client.schedule.motion": {
    default: "0",
    title: { text: "Period", unit: "Seconds" },
    type: "text",
  },

  // GNSS

  "gnss.motion": {
    default: "0",
    title: { text: "Motion", unit: "Seconds" },
    type: "text",
  },
  "gnss.station": {
    default: "0",
    title: { text: "Stationary", unit: "Seconds" },
    type: "text",
  },
  "gnss.invent": {
    default: "0",
    title: { text: "Inventory", unit: "Seconds" },
    type: "text",
  },

  // btn

  "btn.holdoff_time": {
    default: "0",
    title: { text: "Timer", unit: "Seconds" },
    divider: 1000,
    type: "text",
    dependedProp: ["btn.enable", (value) => value.toString() === "86400"],
  },
  "btn.enable": {
    type: "checkbox",
    default: false,
    chBoxConfig: {
      value: (value) => value.toString() === "86400000",
      dependedField: "btn.holdoff_time",
      valueToUpdate: (value) => (value ? "86400" : "20"),
    },
  },

  // light

  "light.enable": {
    type: "checkbox",
    default: false,
  },
  "light.highlim": {
    default: "0",
    title: { text: "High", unit: "Lux" },
    type: "text",
  },
  "light.lowlim": {
    default: "0",
    title: { text: "Low", unit: "Lux" },
    type: "text",
  },

  // Gas
  "gas.enable": {
    type: "checkbox",
    default: false,
  },
  "gas.lowlim": {
    default: "0",
    divider: 1,
    title: { text: "Low", unit: "idx" },
    type: "text",
  },
  "gas.highlim": {
    default: "0",
    divider: 1,
    title: { text: "High", unit: "idx" },
    type: "text",
  },
  "gas.prio": {
    default: 0,
    title: { text: "Priority", unit: "" },
    type: "select",
    dataSet: prioDataSet,
  },
  "gas.schedule.motion": {
    default: "0",
    title: { text: "Motion", unit: "Seconds" },
    type: "text",
  },
  "gas.schedule.station": {
    default: "0",
    title: { text: "Stationary", unit: "Seconds" },
    type: "text",
  },
  "gas.schedule.invent": {
    default: "0",
    title: { text: "Inventory", unit: "Seconds" },
    type: "text",
  },

  // Pressure
  "press.enable": {
    type: "checkbox",
    default: false,
  },
  "press.lowlim": {
    default: "0",
    divider: 100,
    title: { text: "Low", unit: "hPa" },
    type: "text",
  },
  "press.highlim": {
    default: "0",
    divider: 100,
    title: { text: "High", unit: "hPa" },
    type: "text",
  },
  "press.prio": {
    default: 0,
    title: { text: "Priority", unit: "" },
    type: "select",
    dataSet: prioDataSet,
  },
  "press.schedule.motion": {
    default: "0",
    title: { text: "Motion", unit: "Seconds" },
    type: "text",
  },
  "press.schedule.station": {
    default: "0",
    title: { text: "Stationary", unit: "Seconds" },
    type: "text",
  },
  "press.schedule.invent": {
    default: "0",
    title: { text: "Inventory", unit: "Seconds" },
    type: "text",
  },
}
