import Sensors from "./Sensors/Sensors"
import { IDeviceConfig } from "../../APIRequests/APIPut"
import Timers from "./Timers/Timers"
import DeviceType from "../DeviceType"
import { ReactNode } from "react"

interface Props {
  deviceConfig: IDeviceConfig
  saveConfig: (config: IDeviceConfig) => void
  configSchema: { [propName: string]: any } | undefined
}

export default function HubDevice({
  deviceConfig,
  saveConfig,
  configSchema,
}: Props) {
  let tabsConfig = new Map<string, ReactNode>()

  tabsConfig
    .set(
      "Sensors",
      <Sensors
        deviceConfig={deviceConfig}
        saveConfig={saveConfig}
        configSchema={configSchema}
      />
    )
    .set(
      "Timers",
      <Timers
        deviceConfig={deviceConfig}
        saveConfig={saveConfig}
        configSchema={configSchema}
      />
    )

  return <DeviceType tabsConfig={tabsConfig} />
}
