import { Button } from "@material-ui/core"
import customColors from "../../tailwind.customColors.json"
import { useState, ChangeEvent } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { IDeviceConfig } from "../APIRequests/APIPut"
import ConfigBlock from "./ConfigBlock"
import { deviceConfigData, DeviceFormConfigRow } from "./deviceConfigData"

export interface DeviceFormState {
  [p: string]: number | string | boolean
}

export interface DeviceFormConfig {
  [p: string]: string[]
}

export interface DeviceTabComponentProps {
  deviceConfig: IDeviceConfig
  saveConfig: (config: IDeviceConfig) => void
  configSchema: { [p: string]: any } | undefined
  formConfig: DeviceFormConfig
}

export default function DevicePage({
  deviceConfig,
  saveConfig,
  configSchema,
  formConfig,
}: DeviceTabComponentProps) {
  const [formValues, setFormValues] = useState<DeviceFormState>(() => {
    let initialState: DeviceFormState = {}

    Object.entries(formConfig).forEach(([_, keysArr]) =>
      keysArr.forEach((dataKey) => {
        const configKey = getConfigKey(dataKey)
        let configValue = getByPath(deviceConfig, configKey)

        if (typeof configValue === "number") {
          configValue = convertByConfig(
            configValue,
            dataKey,
            { [dataKey]: deviceConfigData[dataKey] },
            "forDisplay"
          )
        }

        if (
          deviceConfigData[dataKey].type === "checkbox" &&
          deviceConfigData[dataKey].chBoxConfig !== undefined
        ) {
          const chBoxConf = deviceConfigData[dataKey].chBoxConfig
          const conditionValue = getByPath(
            deviceConfig,
            chBoxConf!.dependedField!
          )
          configValue = chBoxConf!.value(conditionValue)
        }

        initialState[dataKey] = configValue ?? deviceConfigData[dataKey].default
      })
    )

    return initialState
  })
  const [formErrors, setFormErrors] = useState<Map<string, string>>(new Map())

  function handleValuesChange(
    event: ChangeEvent<HTMLInputElement>,
    propName: string
  ) {
    const dataRow = deviceConfigData[propName]

    let value: string | number | boolean
    let dependedProp: { [p: string]: string | number | boolean } = {}

    if (dataRow.type === "checkbox") {
      if (dataRow.chBoxConfig) {
        dependedProp[dataRow.chBoxConfig.dependedField] =
          dataRow.chBoxConfig.valueToUpdate(event.target.checked)
      }
      // else {
      value = event.target.checked
      // }
    } else {
      value = event.target.value

      if (dataRow.type === "text") value = value.replace(",", ".")
    }

    if (dataRow.dependedProp !== undefined) {
      dependedProp[dataRow.dependedProp[0]] = dataRow.dependedProp[1](value)
    }

    setFormValues((prevState) => {
      return {
        ...prevState,
        [propName]: value,
        ...dependedProp,
      }
    })
  }

  function saveHandler() {
    let formErrors = new Map<string, string>()
    let deviceConfigTemp = { ...deviceConfig }

    Object.entries(formValues).forEach(([dataKey, value]) => {
      if (
        !deviceConfigData[dataKey].readOnly &&
        deviceConfigData[dataKey].chBoxConfig === undefined
      ) {
        const configKey = getConfigKey(dataKey)

        if (deviceConfigData[dataKey].type === "checkbox") {
          setByPath(deviceConfigTemp, configKey, value)
        } else if (deviceConfigData[dataKey].type === "select") {
          setByPath(deviceConfigTemp, configKey, value)
        } else {
          if (isNaN(Number(value))) {
            formErrors.set(dataKey, "Invalid value")
          } else {
            let configValue = convertByConfig(
              Number(value),
              dataKey,
              { [dataKey]: deviceConfigData[dataKey] },
              "forConfig"
            )

            configValue = Math.round(configValue)
            const schemaPath = getSchemaPath(configKey)

            if (configSchema !== undefined) {
              const min = getByPath(configSchema, schemaPath + ".minimum")
              const max = getByPath(configSchema, schemaPath + ".maximum")

              if (
                min !== undefined &&
                configValue < min &&
                typeof min === "number"
              ) {
                let minForDisplay: number = convertByConfig(
                  min,
                  dataKey,
                  { [dataKey]: deviceConfigData[dataKey] },
                  "forDisplay"
                )

                formErrors.set(
                  dataKey,
                  `Value must be greater than or equal to ${minForDisplay}`
                )
              }

              if (
                max !== undefined &&
                configValue > max &&
                typeof max === "number"
              ) {
                let maxForDisplay: number = convertByConfig(
                  max,
                  dataKey,
                  { [dataKey]: deviceConfigData[dataKey] },
                  "forDisplay"
                )

                formErrors.set(
                  dataKey,
                  `Value must be less than or equal to ${maxForDisplay}`
                )
              }
            }

            setByPath(deviceConfigTemp, configKey, configValue)
          }
        }
      }
    })

    setFormErrors(formErrors)

    if (formErrors.size === 0) {
      saveConfig(deviceConfigTemp)
    }
  }

  const useButtonStyles = makeStyles({
    root: {
      "&:focus": {
        outline: "none",
      },
    },
  })
  const classesButton = useButtonStyles()

  return (
    <>
      <Button
        style={{
          backgroundColor: customColors.orange,
          color: "white",
          borderRadius: "0",
          marginBottom: "1rem",
          textTransform: "unset",
        }}
        classes={{ root: classesButton.root }}
        onClick={saveHandler}
      >
        Save settings
      </Button>
      <div className="flex flex-wrap gap-4">
        {Object.entries(formConfig).map(([key, value]) => (
          <ConfigBlock
            key={key}
            title={key}
            values={value}
            formValues={formValues}
            handleChange={handleValuesChange}
            formErrors={formErrors}
          />
        ))}
      </div>
    </>
  )
}

export function getByPath(
  obj: { [p: string]: any },
  path: string
): number | string | boolean | undefined {
  const paths = path.split(".")
  let current: any = obj

  for (let i = 0; i < paths.length; i++) {
    if (current?.[paths[i]] === undefined) {
      return undefined
    } else {
      current = current[paths[i]]
    }
  }
  return current
}

export function setByPath(obj: { [p: string]: any }, path: string, value: any) {
  const paths = path.split(".")
  let current = obj

  for (let i = 0; i < paths.length; i++) {
    if (typeof current[paths[i]] !== "object") {
      current[paths[i]] = value
    } else {
      current = current[paths[i]]
    }
  }
}

export function getSchemaPath(path: string) {
  return "properties." + path.replaceAll(".", ".properties.")
}

export function convertByConfig(
  value: number,
  valuePath: string,
  configObj: DeviceFormConfigRow,
  direction: "forConfig" | "forDisplay"
) {
  let rtnValue: number = value

  if (configObj[valuePath]?.divider !== undefined) {
    rtnValue =
      direction === "forConfig"
        ? rtnValue * (configObj[valuePath].divider ?? 1)
        : rtnValue / (configObj[valuePath].divider ?? 1)
  } else if (configObj[valuePath]?.multiplier !== undefined) {
    rtnValue =
      direction === "forConfig"
        ? rtnValue / (configObj[valuePath].multiplier ?? 1)
        : rtnValue * (configObj[valuePath].multiplier ?? 1)
  }

  return rtnValue
}

export function getConfigKey(propName: string) {
  return propName.split("#")[0]
}
