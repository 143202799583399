import { KeycloakInstance } from "keycloak-js"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core"
import { DeleteOutline } from "@material-ui/icons"
import React, { useEffect, useState } from "react"
import { IGroup } from "./DeviceLayoutContent/Device"
import { APIGetAllGroups } from "./APIRequests/APIGet"
import { useSnackbar } from "notistack"
import { makeStyles } from "@material-ui/core/styles"
import customColors from "../tailwind.customColors.json"
import { APIDelGroup } from "./APIRequests/APIDel"
import { APICreateGroup } from "./APIRequests/APIPost"

export interface Props {
  keycloak: KeycloakInstance
}

export default function GroupMgmPage({ keycloak }: Props) {
  const [groupsForm, setGroupsForm] = useState<IGroup[]>([])
  const [newGroupName, setNewGroupName] = useState<string>("")
  const [dialogOpened, setDialogOpened] = useState<boolean>(false)
  const [deleteRowIndex, setDeleteRowIndex] = useState<number | undefined>()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    APIGetAllGroups({ keycloak }).then((response) => {
      if (response.status === 200) {
        setGroupsForm(response.data)
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" })
      }
    })
  }, [keycloak, enqueueSnackbar])

  function handleDeleteClick() {
    if (deleteRowIndex) {
      APIDelGroup({ keycloak, groupID: groupsForm[deleteRowIndex].id }).then(
        (response) => {
          if (response.status < 300) {
            setGroupsForm((prevState) => {
              let groupsTemp = prevState.slice()
              groupsTemp.splice(deleteRowIndex, 1)

              return groupsTemp
            })

            setDeleteRowIndex(undefined)
            setDialogOpened(false)
          } else {
            enqueueSnackbar(response.data.message, { variant: "error" })
          }
        }
      )
    }
  }

  function createGroup() {
    APICreateGroup({ keycloak, groupName: newGroupName }).then((response) => {
      if (response.status < 300) {
        setGroupsForm((prevState) => {
          let groupsTemp = prevState.slice()
          groupsTemp.push(response.data)

          return groupsTemp
        })

        setNewGroupName("")
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" })
      }
    })
  }

  const useStylesBtn = makeStyles({
    root: {
      backgroundColor: customColors.orange,
      "&:focus": { outline: "none" },
      "&:hover": { backgroundColor: customColors.orange },
      "&$disabled": {
        backgroundColor: customColors.grey,
        color: customColors.popper,
      },
    },
    disabled: {},
  })
  const classesBtn = useStylesBtn()

  const useIconStyles = makeStyles({
    root: {
      color: "black",
      padding: "7px",
      "&:focus": {
        outline: "none",
      },
    },
  })
  const classesIcon = useIconStyles()

  const useButtonStyles = makeStyles({
    root: {
      "&:focus": {
        outline: "none",
      },
    },
  })
  const classesButton = useButtonStyles()

  return (
    <div className="flex flex-col gap-4 p-10">
      <div className="flex">
        <div className="flex p-3 border border-sidebar bg-white w-96">
          <input
            className="focus:outline-none w-full"
            type="text"
            placeholder="New group name"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
        </div>
        <Button
          style={{
            color: "white",
            borderRadius: 0,
            marginLeft: "20px",
            textTransform: "unset",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
          classes={{ root: classesBtn.root, disabled: classesBtn.disabled }}
          onClick={createGroup}
          disabled={newGroupName === ""}
        >
          Create new group
        </Button>
      </div>
      <table
        className="border border-black border-collapse"
        style={{ maxWidth: "60rem" }}
      >
        <thead>
          <tr className="bg-sidebar text-white">
            <td
              className="border border-black p-2 text-center"
              style={{ minWidth: "40rem" }}
            >
              Groups
            </td>
            <td className="w-64 border border-black p-2 text-center">Delete</td>
          </tr>
        </thead>
        <tbody>
          {groupsForm.length === 0 ? (
            <tr className="bg-white">
              <td className="border border-black p-2">&nbsp;</td>
              <td className="border border-black p-2">&nbsp;</td>
            </tr>
          ) : (
            groupsForm.map((elem, index) => (
              <tr className="bg-white" key={index}>
                <td className="border border-black px-2">{elem.name}</td>
                <td className="border border-black px-2 text-center">
                  <IconButton
                    classes={{ root: classesIcon.root }}
                    onClick={() => {
                      setDeleteRowIndex(index)
                      setDialogOpened(true)
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <Dialog
        open={dialogOpened}
        onClose={() => {
          setDialogOpened(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Group Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete{" "}
            <b>
              "
              {deleteRowIndex &&
                groupsForm[deleteRowIndex] &&
                groupsForm[deleteRowIndex].name}
              "
            </b>{" "}
            group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpened(false)
            }}
            classes={{ root: classesButton.root }}
            style={{
              backgroundColor: customColors.sidebar,
              color: "white",
              borderRadius: "0",
              textTransform: "unset",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteClick}
            autoFocus
            classes={{ root: classesButton.root }}
            style={{
              backgroundColor: customColors.sidebar,
              color: "white",
              borderRadius: "0",
              textTransform: "unset",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
